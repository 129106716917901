// Cerebro/ClientApp/src/pages/Dashboard/Subjects/Dialog/useSubjectSearch.js

import { useMemo } from 'react';

const normalizePhoneNumber = (phoneNumber) => {
    return phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
};

const normalizeDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return isNaN(d.getTime()) ? '' : d.toISOString().split('T')[0];
};

const isPhoneNumberMatch = (input, phoneNumber) => {
    if (!phoneNumber) return false;
    const normalizedInput = input.replace(/[^\d@]/g, '');
    const normalizedPhone = normalizePhoneNumber(phoneNumber);

    // If the input contains @, it's not a phone number
    if (normalizedInput.includes('@')) return false;

    // If the input is purely numeric, do a substring match
    if (/^\d+$/.test(normalizedInput)) {
        return normalizedPhone.includes(normalizedInput);
    }

    // Otherwise, use the original logic
    return normalizedInput.length > 0 && normalizedInput.split('').every(digit => {
        const index = normalizedPhone.indexOf(digit);
        if (index !== -1) {
            normalizedPhone = normalizedPhone.slice(index + 1);
            return true;
        }
        return false;
    });
};

const isMatch = (value, searchTerm) => {
    if (typeof value === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (value instanceof Date) {
        return normalizeDate(value).includes(searchTerm);
    }
    return String(value).toLowerCase().includes(searchTerm.toLowerCase());
};

export const useSubjectSearch = (subjects, searchTerm) => {
    return useMemo(() => {
        if (!searchTerm?.trim()) {
            return subjects;
        }
        const normalizedSearchTerm = searchTerm.toLowerCase().trim();
        return subjects.filter(subject => {
            const fullName = `${subject.FirstName} ${subject.LastName}`.trim().toLowerCase();
            const dob = normalizeDate(subject.DateOfBirth);
            const age = dob ? new Date().getFullYear() - new Date(dob).getFullYear() : '';
            return (
                isMatch(fullName, normalizedSearchTerm) ||
                isMatch(dob, normalizedSearchTerm) ||
                isMatch(age, normalizedSearchTerm) ||
                isMatch(subject.SubjectNumber, normalizedSearchTerm) ||
                isPhoneNumberMatch(normalizedSearchTerm, subject.PhoneNumber) ||
                isMatch(subject.EmailAddress, normalizedSearchTerm)
            );
        });
    }, [subjects, searchTerm]);
};
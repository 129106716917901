// Cerebro/ClientApp/src/pages/Dashboard/EHR/EHR.js

import React, { useState } from 'react';
import './EHR.css';
import AddPatient from './Dialog/AddPatient';
import EditPatient from './Dialog/EditPatient';
import useFetchWithAuth from '../../../utils/useFetchWithAuth';
import InputMask from 'react-input-mask';
import { formatPhoneNumber, stripPhoneFormatting, formatDateForDisplay } from '../../../utils/formatters';

const EHR = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('lastName');
    const [showAddPatient, setShowAddPatient] = useState(false);
    const [showEditPatient, setShowEditPatient] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const { fetchWithAuth } = useFetchWithAuth();

    const handleSearchChange = (e) => {
        const value = e.target.value;
        if (searchType === 'phone') {
            setSearchTerm(formatPhoneNumber(value));
        } else {
            setSearchTerm(value);
        }
    };

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        setSearchTerm(''); // Clear search term when changing type
    };

    const handleAddPatientClick = () => {
        setShowAddPatient(true);
    };

    const handleCloseAddPatient = () => {
        setShowAddPatient(false);
    };

    const handleSearch = async () => {
        try {
            let searchTermToUse = searchTerm;
            if (searchType === 'phone') {
                searchTermToUse = stripPhoneFormatting(searchTerm);
            }

            const response = await fetchWithAuth(
                `/api/patient/search?searchTerm=${encodeURIComponent(searchTermToUse)}&searchType=${searchType}`
            );
            const resultsArray = response.$values || [];
            setSearchResults(resultsArray);
        } catch (error) {
            setSearchResults([]);
        }
    };

    const handlePatientClick = async (patient) => {
        try {
            const fetchedPatient = await fetchWithAuth(`/api/patient/${patient.PatientId}`);
            setSelectedPatient(fetchedPatient);
            setShowEditPatient(true);
        } catch (error) {
            console.error('Error fetching patient data:', error);
            // Optionally, show an error message to the user
        }
    };


    const handlePatientUpdated = (updatedPatient) => {
        setSearchResults(prevResults =>
            prevResults.map(patient =>
                patient.PatientId === updatedPatient.PatientId ? updatedPatient : patient
            )
        );
    };

    const handlePatientDeleted = (deletedPatientId) => {
        setSearchResults(prevResults =>
            prevResults.filter(patient => patient.PatientId !== deletedPatientId)
        );
    };

    const handleCloseEditPatient = () => {
        setShowEditPatient(false);
        setSelectedPatient(null);
    };

    const renderSearchInput = () => {
        switch (searchType) {
            case 'phone':
                return (
                    <InputMask
                        mask="(999) 999-9999"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Enter phone number..."
                        className="search-input"
                    />
                );
            case 'dob':
                return (
                    <InputMask
                        mask="99/99/9999"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="mm/dd/yyyy"
                        className="search-input"
                    />
                );
            default:
                return (
                    <input
                        type="text"
                        placeholder="Search patients..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                );
        }
    };

    return (
        <div className="ehr-container">
            <h1 className="ehr-title">Electronic Health Records</h1>
            <div className="search-container">
                <div className="search-options">
                    <label htmlFor="searchType">Search for patient by: </label>
                    <select
                        id="searchType"
                        value={searchType}
                        onChange={handleSearchTypeChange}
                        className="search-type-dropdown"
                    >
                        <option value="lastName">Last Name</option>
                        <option value="dob">Date of Birth</option>
                        <option value="phone">Phone</option>
                    </select>
                </div>
                {renderSearchInput()}
                <button onClick={handleSearch} className="search-button">Search</button>
            </div>
            <button className="add-patient-button" onClick={handleAddPatientClick}>
                Add Patient
            </button>
            {searchResults !== null && (
                <div className="search-results">
                    {searchResults.length > 0 ? (
                        <table className="results-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone Number</th>
                                    <th>Date of Birth</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map((patient) => (
                                    <tr key={patient.PatientId} onClick={() => handlePatientClick(patient)}>
                                        <td>{`${patient.FirstName} ${patient.LastName}`}</td>
                                        <td>{formatPhoneNumber(patient.PhoneNumber)}</td>
                                        <td>{formatDateForDisplay(patient.DateOfBirth)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                    <p className="no-results-message">No patients found matching the search criteria.</p>                    )}
                </div>
            )}
            {showAddPatient && (
                <div className="modal-overlay">
                    <AddPatient onClose={handleCloseAddPatient} />
                </div>
            )}
            {showEditPatient && selectedPatient && (
                <div className="modal-overlay">
                    <EditPatient
                        patient={selectedPatient}
                        onClose={handleCloseEditPatient}
                        onPatientUpdated={handlePatientUpdated}
                        onPatientDeleted={handlePatientDeleted}
                    />
                </div>
            )}
        </div>
    );
};

export default EHR;
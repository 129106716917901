// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/DayView.js

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    format,
    addMinutes,
    eachMinuteOfInterval,
    isToday,
    isSameDay,
} from 'date-fns';
import { Clock, User } from 'lucide-react';

const DayView = ({
    currentDate,
    visits,
    onVisitClick,
    initialScrollPosition,
    scrollToCurrentTimeOnInitialMount,
    onScrollChange
}) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [linePosition, setLinePosition] = useState(null); // For current-time-line positioning
    const dayContentRef = useRef(null);
    const todayTimeGridRef = useRef(null);
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);

    const START_HOUR = 7;
    const END_HOUR = 19;

    const dayStart = new Date(currentDate);
    dayStart.setHours(START_HOUR, 0, 0, 0);
    const dayEnd = new Date(currentDate);
    dayEnd.setHours(END_HOUR, 0, 0, 0);
    const timeSlots = eachMinuteOfInterval({ start: dayStart, end: dayEnd }, { step: 15 });
    const totalMinutesInDay = timeSlots.length * 15;

    const updateCurrentTime = () => {
        setCurrentTime(new Date());
    };

    // Update current time on the minute
    useEffect(() => {
        const handleTimeUpdate = () => {
            updateCurrentTime();
        };

        const now = new Date();
        const msUntilNextMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

        timeoutRef.current = setTimeout(() => {
            handleTimeUpdate();
            intervalRef.current = setInterval(handleTimeUpdate, 60000);
        }, msUntilNextMinute);

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);

    // Handle initial scroll using useLayoutEffect so it happens before paint
    useLayoutEffect(() => {
        if (dayContentRef.current) {
            if (initialScrollPosition != null) {
                dayContentRef.current.scrollTop = initialScrollPosition;
                if (onScrollChange) {
                    onScrollChange(dayContentRef.current.scrollTop);
                }
            } else if (scrollToCurrentTimeOnInitialMount) {
                const now = new Date();
                const currentHour = now.getHours();
                if (currentHour >= START_HOUR && currentHour < END_HOUR) {
                    const minutesSinceStart = (currentHour - START_HOUR) * 60 + now.getMinutes();
                    const timeGridHeight =
                        dayContentRef.current.scrollHeight - dayContentRef.current.clientHeight;
                    const scrollPosition = (minutesSinceStart / totalMinutesInDay) * timeGridHeight;
                    dayContentRef.current.scrollTop = scrollPosition;
                    if (onScrollChange) {
                        onScrollChange(dayContentRef.current.scrollTop);
                    }
                }
            }
        }
    }, [initialScrollPosition, scrollToCurrentTimeOnInitialMount, onScrollChange, totalMinutesInDay]);

    // Attach scroll listener
    useEffect(() => {
        const handleUserScroll = () => {
            if (dayContentRef.current && onScrollChange) {
                onScrollChange(dayContentRef.current.scrollTop);
            }
        };

        const dayContent = dayContentRef.current;
        if (dayContent) {
            dayContent.addEventListener('scroll', handleUserScroll);
        }

        return () => {
            if (dayContent) {
                dayContent.removeEventListener('scroll', handleUserScroll);
            }
        };
    }, [onScrollChange]);

    const isCurrentTimeSlot = (slotTime) => {
        const now = currentTime;
        return now >= slotTime && now < addMinutes(slotTime, 15);
    };

    const getVisitsForSlot = (slot) => {
        return visits.filter((visit) => {
            const visitDate = new Date(visit.scheduledDateTime);
            return (
                isSameDay(visitDate, currentDate) &&
                visitDate >= slot &&
                visitDate < addMinutes(slot, 15)
            );
        });
    };

    const calculateVisitPosition = (visit, allDayVisits) => {
        const visitStart = new Date(visit.scheduledDateTime);
        const minutesSinceStart =
            (visitStart.getHours() - START_HOUR) * 60 + visitStart.getMinutes();
        const top = (minutesSinceStart / totalMinutesInDay) * 100;
        const height = (visit.durationMinutes / totalMinutesInDay) * 100;

        const overlappingVisits = allDayVisits.filter((v) => {
            const vStart = new Date(v.scheduledDateTime);
            const vEnd = addMinutes(vStart, v.durationMinutes);
            const thisStart = visitStart;
            const thisEnd = addMinutes(thisStart, visit.durationMinutes);
            return !(vEnd <= thisStart || vStart >= thisEnd);
        });

        const position = overlappingVisits.indexOf(visit);
        const width = overlappingVisits.length > 0 ? 100 / overlappingVisits.length : 100;
        const left = position * width;

        return { top, height, width, left };
    };

    // Compute line position using layout effect for immediate painting
    useLayoutEffect(() => {
        if (!isToday(currentDate) || !todayTimeGridRef.current) {
            setLinePosition(null);
            return;
        }

        const timeGridHeight = todayTimeGridRef.current.offsetHeight;
        const now = currentTime;
        const currentHour = now.getHours();

        if (currentHour >= START_HOUR && currentHour < END_HOUR) {
            const minutesSinceStart =
                (currentHour - START_HOUR) * 60 + now.getMinutes();
            const top = (minutesSinceStart / totalMinutesInDay) * timeGridHeight;
            setLinePosition(top);
        } else {
            setLinePosition(null);
        }
    }, [currentDate, currentTime, totalMinutesInDay]);

    const dayVisits = visits.filter((visit) =>
        isSameDay(new Date(visit.scheduledDateTime), currentDate)
    );

    const renderVisit = (visit, allDayVisits) => {
        const { top, height, width, left } = calculateVisitPosition(visit, allDayVisits);
        const startTime = format(new Date(visit.scheduledDateTime), 'h:mm a');
        const endTime = format(
            addMinutes(new Date(visit.scheduledDateTime), visit.durationMinutes),
            'h:mm a'
        );

        return (
            <div
                key={visit.visitId}
                className={`week-visit status-${visit.status.toLowerCase()}`}
                style={{ top: `${top}%`, height: `${height}%`, width: `${width}%`, left: `${left}%` }}
                onClick={() => onVisitClick(visit)}
            >
                <div className="visit-header">
                    <span className="visit-time">
                        <Clock size={12} className="visit-icon" />
                        {startTime} - {endTime}
                    </span>
                    <span className={`visit-status status-${visit.status.toLowerCase()}`}>
                        {visit.status}
                    </span>
                </div>
                <span className="visit-patient">
                    <User size={12} className="visit-icon" />
                    {visit.patientName}
                </span>
                {visit.templateName && (
                    <span className="visit-template">{visit.templateName}</span>
                )}
            </div>
        );
    };

    return (
        <div className="day-view">
            <div className="calendar-header">
                <div className="time-column-header"></div>
                <div className={`weekday-header${isToday(currentDate) ? ' today' : ''}`}>
                    <span className="weekday-full">{format(currentDate, 'EEEE')}</span>
                    <span className="weekday-short">{format(currentDate, 'EEE')}</span>
                    <div className="weekday-date">{format(currentDate, 'MMM d')}</div>
                </div>
            </div>

            <div className="week-content" ref={dayContentRef} style={{ position: 'relative' }}>
                <div className="time-column">
                    {timeSlots.map((time) => (
                        <div
                            key={time.toString()}
                            className={`time-slot${isCurrentTimeSlot(time) ? ' current-timeslot' : ''}
                                ${format(time, 'mm') === '00' || format(time, 'mm') === '30'
                                    ? ' half-hour-line'
                                    : ''
                                }`}
                        >
                            <span
                                className={`time-label ${format(time, 'mm') === '00' ? 'hour-label' : 'minute-label'
                                    }`}
                            >
                                {format(time, 'h:mm a')}
                            </span>
                        </div>
                    ))}
                </div>

                <div className={`day-column${isToday(currentDate) ? ' today' : ''}`}>
                    <div className="time-grid" ref={todayTimeGridRef}>
                        {timeSlots.map((time) => (
                            <React.Fragment key={time.toString()}>
                                <div
                                    className={`grid-line${isCurrentTimeSlot(time) ? ' current-timeslot' : ''
                                        }${format(time, 'mm') === '00'
                                            ? ' hour-line'
                                            : format(time, 'mm') === '30'
                                                ? ' half-hour-line'
                                                : ''
                                        }`}
                                >
                                    <div className="time-slot-overlay"></div>
                                </div>

                                {getVisitsForSlot(time).map((visit) =>
                                    renderVisit(visit, dayVisits)
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                {linePosition !== null && (
                    <div
                        className="current-time-line"
                        style={{
                            position: 'absolute',
                            left: '6rem', // if desired to match the offset in week view, or leave as 0 if you prefer
                            right: '0',
                            top: `${linePosition}px`,
                            height: '2px',
                            backgroundColor: '#197FD0',
                            zIndex: '3'
                        }}
                    ></div>
                )}
            </div>
        </div>
    );
};

export default DayView;

// Cerebro/ClientApp/src/pages/Dashboard/Subjects/Dialog/useImportSubjectSearch.js

import { useMemo } from 'react';

const normalizePhoneNumber = (phoneNumber) => {
    return phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
};

const isPhoneNumberMatch = (input, phoneNumber) => {
    if (!phoneNumber) return false;
    const normalizedInput = input.replace(/[^\d@]/g, '');
    const normalizedPhone = normalizePhoneNumber(phoneNumber);

    // If the input contains @, it's not a phone number
    if (normalizedInput.includes('@')) return false;

    // If the input is purely numeric, do a substring match
    if (/^\d+$/.test(normalizedInput)) {
        return normalizedPhone.includes(normalizedInput);
    }

    // Otherwise, use the original logic
    return normalizedInput.length > 0 && normalizedInput.split('').every(digit => {
        const index = normalizedPhone.indexOf(digit);
        if (index !== -1) {
            normalizedPhone = normalizedPhone.slice(index + 1);
            return true;
        }
        return false;
    });
};

export const useSubjectSearch = (subjects, searchTerm) => {
    return useMemo(() => {
        if (!Array.isArray(subjects) || subjects.length === 0 || !searchTerm?.trim()) {
            return subjects || [];
        }
        const lowercasedSearchTerm = searchTerm.toLowerCase().trim();
        return subjects.filter(subject => {
            const fullNameMatch = (subject.FullName || '').toLowerCase().includes(lowercasedSearchTerm);
            const emailMatch = (subject.Email || '').toLowerCase().includes(lowercasedSearchTerm);
            const phoneMatch = isPhoneNumberMatch(lowercasedSearchTerm, subject.PhoneNumber);
            const protocolMatch = (subject.ProtocolNumber || '').toLowerCase().includes(lowercasedSearchTerm);
            return fullNameMatch || emailMatch || phoneMatch || protocolMatch;
        });
    }, [subjects, searchTerm]);
};
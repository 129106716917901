import React from 'react';

const Inventory = () => {
    return (
        <div>
            <h1>Inventory</h1>
            {/* Add your Investigational Product page content here */}
        </div>
    );
};

export default Inventory;

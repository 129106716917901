// src/components/api-authorization/AuthorizeService.js

import { jwtDecode } from 'jwt-decode';

class AuthorizeService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _isAuthenticated = false;

    async isAuthenticated() {
        const token = this.getToken();
        return !!token;
    }

    getToken() {
        const token = localStorage.getItem('auth_token');
        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 < Date.now()) {
                this.setToken(null);
                return null;
            }
        }
        return token;
    }

    setToken(token) {
        if (token) {
            localStorage.setItem('auth_token', token);
            localStorage.setItem('last_login', Date.now().toString());
            this._isAuthenticated = true;
        } else {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('last_login');
            this._isAuthenticated = false;
        }
        this.notifySubscribers();
    }

    getLastLoginTimestamp() {
        return parseInt(localStorage.getItem('last_login') || '0', 10);
    }

    async getUser() {
        const token = this.getToken();
        if (!token) return null;

        try {
            const decodedToken = jwtDecode(token);
            return decodedToken;
        } catch (error) {
            return null;
        }
    }

    async signIn(userData) {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return { status: 'error', message: errorData.message };
            }

            const data = await response.json();
            if (data.token) {
                this.setToken(data.token);
                return { status: 'success', timestamp: this.getLastLoginTimestamp() };
            } else {
                return { status: 'error', message: 'Token not found in response.' };
            }
        } catch (error) {
            return { status: 'error', message: 'An error occurred during sign-in.' };
        }
    }

    async signOut() {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/auth/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                this.setToken(null);
                // Clear the browser history
                window.history.pushState(null, '', '/login');
                return { status: 'success' };
            } else {
                const errorData = await response.json();
                return { status: 'error', message: errorData.message };
            }
        } catch (error) {
            return { status: 'error', message: 'An error occurred during logout.' };
        }
    }

    async fetchUserRoles() {
        try {
            const token = this.getToken();
            if (!token) {
                return null;
            }

            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/userroles`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user roles');
            }

            const userRoles = await response.json();
            return userRoles;
        } catch (error) {
            return null;
        }
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }
}

const authService = new AuthorizeService();
export default authService;

export const AuthenticationResultStatus = {
    Success: 'success',
    Fail: 'fail'
};
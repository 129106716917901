// ImportSubject.js

import React, { useState, useEffect } from 'react';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { X } from 'lucide-react';
import './ImportSubject.css';
import { useSubjectSearch } from './useImportSubjectSearch';

const ImportSubject = ({ onClose, onImportSuccess }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [interestedSubjects, setInterestedSubjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const filteredSubjects = useSubjectSearch(interestedSubjects, searchTerm);

    useEffect(() => {
        fetchInterestedSubjects();
    }, []);

    const fetchInterestedSubjects = async () => {
        try {
            const response = await fetchWithAuth('/api/InterestedSubject/nonimported');

            if (response && response.$values && Array.isArray(response.$values)) {
                setInterestedSubjects(response.$values);
            } else {
                showMessage('error', 'Received unexpected data format from server');
                setInterestedSubjects([]);
            }
        } catch (error) {
            console.error('Error fetching subjects:', error);
            showMessage('error', 'Failed to fetch interested subjects');
            setInterestedSubjects([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImport = async (subject) => {
        try {
            const response = await fetchWithAuth('/api/subject/import', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(subject),
            });

            if (response && response.SubjectId) {
                showMessage('success', 'Subject imported successfully');
                setInterestedSubjects(prevSubjects =>
                    prevSubjects.filter(s => s.InterestedSubjectId !== subject.InterestedSubjectId)
                );
                onImportSuccess(response);
            } else {
                console.error('Unexpected response:', response);
                showMessage('error', 'Failed to import subject: Unexpected response format');
            }
        } catch (error) {
            console.error('Error importing subject:', error);
            showMessage('error', `An error occurred while importing the subject: ${error.message}`);
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return 'N/A';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    return (
        <div className="import-subjects-modal">
            <div className="import-subjects-content">
                <div className="import-subjects-header">
                    <h2>Import Interested Subjects</h2>
                    <button onClick={onClose} className="close-button">
                        <X size={24} />
                    </button>
                </div>
                <input
                    type="text"
                    placeholder="Search subjects..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                {isLoading ? (
                    <p className="loading-message">Loading...</p>
                ) : (
                    <div className="table-container">
                        <p>Showing {filteredSubjects.length} of {interestedSubjects.length} subjects</p>
                        <table className="interested-subjects-table">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Protocol Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSubjects.map((subject) => (
                                    <tr key={subject.InterestedSubjectId || `subject-${Math.random()}`}>
                                        <td>{subject.FullName || 'N/A'}</td>
                                        <td>{subject.Email || 'N/A'}</td>
                                        <td>{formatPhoneNumber(subject.PhoneNumber)}</td>
                                        <td>{subject.ProtocolNumber || 'N/A'}</td>
                                        <td>
                                            <button onClick={() => handleImport(subject)} className="import-button">
                                                Import
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImportSubject;
// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/VisitForm.js

import React, { useState, useEffect } from 'react';
import { format, parse, isValid } from 'date-fns';
import './VisitForm.css';

const VisitForm = ({ visit, templates, onSave, onCancel, loading }) => {
    const [formData, setFormData] = useState({
        patientId: '',
        scheduledDateTime: new Date(),
        durationMinutes: 30,
        status: 'Scheduled',
        notes: '',
        visitTemplateId: '',
        height: '',
        weight: '',
        bloodPressure: '',
        temperature: '',
        heartRate: '',
        responses: []
    });

    const [errors, setErrors] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        if (visit) {
            setFormData({
                ...visit,
                scheduledDateTime: new Date(visit.scheduledDateTime)
            });
            if (visit.visitTemplateId) {
                const template = templates.find(t => t.visitTemplateId === visit.visitTemplateId);
                setSelectedTemplate(template);
            }
        }
    }, [visit, templates]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when field is modified
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleDateTimeChange = (e) => {
        const { name, value } = e.target;
        let newDateTime = new Date(formData.scheduledDateTime);

        if (name === 'date') {
            const [year, month, day] = value.split('-');
            newDateTime.setFullYear(year, month - 1, day);
        } else if (name === 'time') {
            const [hours, minutes] = value.split(':');
            newDateTime.setHours(hours, minutes);
        }

        if (isValid(newDateTime)) {
            setFormData(prev => ({
                ...prev,
                scheduledDateTime: newDateTime
            }));
            if (errors.scheduledDateTime) {
                setErrors(prev => ({
                    ...prev,
                    scheduledDateTime: ''
                }));
            }
        }
    };

    const handleTemplateChange = (e) => {
        const templateId = e.target.value;
        const template = templates.find(t => t.visitTemplateId === templateId);
        setSelectedTemplate(template);

        setFormData(prev => ({
            ...prev,
            visitTemplateId: templateId,
            // Initialize responses for the new template
            responses: template ? template.fields.map(field => ({
                visitTemplateFieldId: field.visitTemplateFieldId,
                response: '',
                fieldLabel: field.label
            })) : []
        }));
    };

    const handleResponseChange = (fieldId, value) => {
        setFormData(prev => ({
            ...prev,
            responses: prev.responses.map(response =>
                response.visitTemplateFieldId === fieldId
                    ? { ...response, response: value }
                    : response
            )
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.patientId) {
            newErrors.patientId = 'Patient is required';
        }

        if (!isValid(formData.scheduledDateTime)) {
            newErrors.scheduledDateTime = 'Valid date and time are required';
        }

        if (!formData.durationMinutes || formData.durationMinutes < 15) {
            newErrors.durationMinutes = 'Duration must be at least 15 minutes';
        }

        // Validate required template fields
        if (selectedTemplate) {
            selectedTemplate.fields.forEach(field => {
                if (field.isRequired) {
                    const response = formData.responses.find(
                        r => r.visitTemplateFieldId === field.visitTemplateFieldId
                    );
                    if (!response || !response.response.trim()) {
                        newErrors[`field_${field.visitTemplateFieldId}`] = 'This field is required';
                    }
                }
            });
        }

        // Validate vitals if provided
        if (formData.height && isNaN(parseFloat(formData.height))) {
            newErrors.height = 'Height must be a valid number';
        }
        if (formData.weight && isNaN(parseFloat(formData.weight))) {
            newErrors.weight = 'Weight must be a valid number';
        }
        if (formData.heartRate && isNaN(parseInt(formData.heartRate))) {
            newErrors.heartRate = 'Heart rate must be a valid number';
        }
        if (formData.temperature && isNaN(parseFloat(formData.temperature))) {
            newErrors.temperature = 'Temperature must be a valid number';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSave(formData);
        }
    };

    const renderVitalsSection = () => (
        <div className="form-section">
            <h3>Vitals</h3>
            <div className="vitals-grid">
                <div className="form-group">
                    <label htmlFor="height">Height (cm)</label>
                    <input
                        type="number"
                        id="height"
                        name="height"
                        value={formData.height}
                        onChange={handleInputChange}
                        step="0.1"
                        className={errors.height ? 'error' : ''}
                    />
                    {errors.height && <span className="error-message">{errors.height}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="weight">Weight (kg)</label>
                    <input
                        type="number"
                        id="weight"
                        name="weight"
                        value={formData.weight}
                        onChange={handleInputChange}
                        step="0.1"
                        className={errors.weight ? 'error' : ''}
                    />
                    {errors.weight && <span className="error-message">{errors.weight}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="bloodPressure">Blood Pressure</label>
                    <input
                        type="text"
                        id="bloodPressure"
                        name="bloodPressure"
                        value={formData.bloodPressure}
                        onChange={handleInputChange}
                        placeholder="120/80"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="temperature">Temperature (�F)</label>
                    <input
                        type="number"
                        id="temperature"
                        name="temperature"
                        value={formData.temperature}
                        onChange={handleInputChange}
                        step="0.1"
                        className={errors.temperature ? 'error' : ''}
                    />
                    {errors.temperature && <span className="error-message">{errors.temperature}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="heartRate">Heart Rate (bpm)</label>
                    <input
                        type="number"
                        id="heartRate"
                        name="heartRate"
                        value={formData.heartRate}
                        onChange={handleInputChange}
                        className={errors.heartRate ? 'error' : ''}
                    />
                    {errors.heartRate && <span className="error-message">{errors.heartRate}</span>}
                </div>
            </div>
        </div>
    );

    const renderTemplateFields = () => {
        if (!selectedTemplate) return null;

        return (
            <div className="form-section">
                <h3>{selectedTemplate.name}</h3>
                <p className="template-description">{selectedTemplate.description}</p>
                <div className="template-fields">
                    {selectedTemplate.fields.map(field => (
                        <div key={field.visitTemplateFieldId} className="form-group">
                            <label htmlFor={`field_${field.visitTemplateFieldId}`}>
                                {field.label}
                                {field.isRequired && <span className="required">*</span>}
                            </label>
                            {renderTemplateField(field)}
                            {errors[`field_${field.visitTemplateFieldId}`] && (
                                <span className="error-message">
                                    {errors[`field_${field.visitTemplateFieldId}`]}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderTemplateField = (field) => {
        const response = formData.responses.find(
            r => r.visitTemplateFieldId === field.visitTemplateFieldId
        );
        const value = response ? response.response : '';

        switch (field.fieldType) {
            case 'text':
                return (
                    <input
                        type="text"
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) => handleResponseChange(field.visitTemplateFieldId, e.target.value)}
                        className={errors[`field_${field.visitTemplateFieldId}`] ? 'error' : ''}
                    />
                );
            case 'number':
                return (
                    <input
                        type="number"
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) => handleResponseChange(field.visitTemplateFieldId, e.target.value)}
                        className={errors[`field_${field.visitTemplateFieldId}`] ? 'error' : ''}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) => handleResponseChange(field.visitTemplateFieldId, e.target.value)}
                        className={errors[`field_${field.visitTemplateFieldId}`] ? 'error' : ''}
                        rows={4}
                    />
                );
            case 'select':
                return (
                    <select
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) => handleResponseChange(field.visitTemplateFieldId, e.target.value)}
                        className={errors[`field_${field.visitTemplateFieldId}`] ? 'error' : ''}
                    >
                        <option value="">Select...</option>
                        {field.options.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit} className="visit-form">
            <div className="form-section">
                <h3>Visit Details</h3>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="date">Date</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={format(formData.scheduledDateTime, 'yyyy-MM-dd')}
                            onChange={handleDateTimeChange}
                            className={errors.scheduledDateTime ? 'error' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="time">Time</label>
                        <input
                            type="time"
                            id="time"
                            name="time"
                            value={format(formData.scheduledDateTime, 'HH:mm')}
                            onChange={handleDateTimeChange}
                            className={errors.scheduledDateTime ? 'error' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="durationMinutes">Duration</label>
                        <select
                            id="durationMinutes"
                            name="durationMinutes"
                            value={formData.durationMinutes}
                            onChange={handleInputChange}
                            className={errors.durationMinutes ? 'error' : ''}
                        >
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="45">45 minutes</option>
                            <option value="60">1 hour</option>
                            <option value="90">1.5 hours</option>
                            <option value="120">2 hours</option>
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="visitTemplateId">Visit Template</label>
                    <select
                        id="visitTemplateId"
                        name="visitTemplateId"
                        value={formData.visitTemplateId}
                        onChange={handleTemplateChange}
                    >
                        <option value="">Select a template...</option>
                        {templates.map(template => (
                            <option key={template.visitTemplateId} value={template.visitTemplateId}>
                                {template.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                    <textarea
                        id="notes"
                        name="notes"
                        value={formData.notes}
                        onChange={handleInputChange}
                        rows={4}
                    />
                </div>
            </div>

            {['InProgress', 'Completed'].includes(formData.status) && renderVitalsSection()}
            {selectedTemplate && renderTemplateFields()}

            <div className="form-actions">
                <button
                    type="button"
                    className="cancel-button"
                    onClick={onCancel}
                    disabled={loading}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="save-button"
                    disabled={loading}
                >
                    {loading ? 'Saving...' : 'Save Visit'}
                </button>
            </div>
        </form>
    );
};

export default VisitForm;
// Cerebro/ClientApp/src/components/EditSubject/EditSubject.js

import React, { useState, useEffect } from 'react';
import './AddSubject.css';
import backIcon from '../../../../assets/icons/Close.png';
import editIcon from '../../../../assets/icons/Edit.png';
import deleteIcon from '../../../../assets/icons/Delete.png';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { formatDateOnly } from '../../../../utils/formatDateOnly';

const EditSubject = ({ subject, onClose, onDelete, onSubjectUpdated }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [activeTab, setActiveTab] = useState('personalinfo');
    const [subjectData, setSubjectData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [protocols, setProtocols] = useState([]);
    const [errors, setErrors] = useState({});
    const [originalSubjectData, setOriginalSubjectData] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState(['C', 'P', 'F']);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [subjectResponse, protocolsResponse] = await Promise.all([
                    fetchWithAuth(`/api/subject/${subject.SubjectId}`),
                    fetchWithAuth('/api/protocol')
                ]);

                // Extract related entities from $values if necessary
                const extractArray = (data) => Array.isArray(data) ? data : (data && data.$values) || [];

                const formattedData = {
                    ...subjectResponse,
                    MedicalHistories: extractArray(subjectResponse.MedicalHistories),
                    Medications: extractArray(subjectResponse.Medications),
                    Notes: extractArray(subjectResponse.Notes),
                    CareTeams: extractArray(subjectResponse.CareTeams)
                };
                setSubjectData(formattedData);
                setOriginalSubjectData(JSON.parse(JSON.stringify(formattedData)));

                const protocolsArray = protocolsResponse.$values || [];
                setProtocols(protocolsArray);

            } catch (error) {
                console.error('Error fetching data:', error);
                showMessage('error', 'Failed to load data: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [subject.SubjectId, fetchWithAuth, showMessage]);

    const handleTypeFilterClick = (type) => {
        if (type === 'ALL') {
            if (selectedTypes.length === 3) {
                // All types are selected, unselect all
                setSelectedTypes([]);
            } else {
                // Not all types are selected, select all
                setSelectedTypes(['C', 'P', 'F']);
            }
        } else {
            let newSelectedTypes;
            if (selectedTypes.includes(type)) {
                // Remove the type
                newSelectedTypes = selectedTypes.filter(t => t !== type);
            } else {
                // Add the type
                newSelectedTypes = [...selectedTypes, type];
            }
            setSelectedTypes(newSelectedTypes);
        }
    };

    const handleMedicalHistoryTypeChange = (index, value) => {
        setSubjectData(prevData => {
            const newMedicalHistories = [...prevData.MedicalHistories];
            newMedicalHistories[index] = {
                ...newMedicalHistories[index],
                Type: value
            };
            return { ...prevData, MedicalHistories: newMedicalHistories };
        });
        setUnsavedChanges(true);
    };

    const getTypeColor = (type) => {
        switch (type) {
            case 'F':
                return '#00A02F'; // Family Dx
            case 'P':
                return '#F8C706'; // Previous Dx
            case 'C':
            default:
                return '#1359A1'; // Current Dx
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSubjectData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setUnsavedChanges(true);
    };

    const handleArrayInputChange = (e, index, arrayName) => {
        const { name, value } = e.target;
        setSubjectData(prevData => {
            const newArray = [...prevData[arrayName]];
            if (name === 'Tags') {
                const newTags = value.split(',').map(tag => ({ Tag: tag.trim() }));
                newArray[index] = {
                    ...newArray[index],
                    Tags: {
                        $values: newTags
                    }
                };
            } else {
                newArray[index] = {
                    ...newArray[index],
                    [name]: value
                };
            }
            return { ...prevData, [arrayName]: newArray };
        });
        setUnsavedChanges(true);
    };

    const addArrayItem = (arrayName) => {
        const currentDate = formatDateOnly(new Date());

        setSubjectData(prevData => ({
            ...prevData,
            [arrayName]: [
                ...prevData[arrayName],
                arrayName === 'Notes' ? {
                    Content: '',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : arrayName === 'Medications' ? {
                    Name: '',
                    Dosage: '',
                    Frequency: '',
                    StartDate: '',
                    EndDate: '',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : arrayName === 'MedicalHistories' ? {
                    Condition: '',
                    DiagnosisDate: '',
                    Description: '',
                    Tags: [],
                    Type: 'C',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : arrayName === 'CareTeams' ? {
                    Name: '',
                    Specialty: '',
                    Phone: '',
                    Fax: '',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : {}
            ]
        }));
        setUnsavedChanges(true);
    };

    const removeArrayItem = (index, arrayName) => {
        setSubjectData(prevData => ({
            ...prevData,
            [arrayName]: prevData[arrayName].filter((_, i) => i !== index)
        }));
        setUnsavedChanges(true);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!subjectData.FirstName?.trim()) {
            newErrors.FirstName = 'First Name is required.';
        }
        if (!subjectData.LastName?.trim()) {
            newErrors.LastName = 'Last Name is required.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const updatePayload = {
                    ...subjectData,
                    MedicalHistories: subjectData.MedicalHistories.map(history => ({
                        ...history,
                        DiagnosisDate: history.DiagnosisDate || null,
                        Tags: Array.isArray(history.Tags?.$values)
                            ? history.Tags.$values.map(tag => ({
                                Tag: tag.Tag
                            }))
                            : []
                    }))
                };

                const response = await fetchWithAuth(`/api/subject/${subjectData.SubjectId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatePayload),
                });

                if (response && response.SubjectId) {
                    showMessage('success', 'Subject updated successfully!', 3000);
                    setIsEditing(false);
                    setUnsavedChanges(false);
                    if (onSubjectUpdated) {
                        onSubjectUpdated(response);
                    }
                } else if (response && response.message) {
                    showMessage('error', response.message, 0);
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                showMessage('error', 'Error updating subject: ' + (error.message || 'Unknown error'), 0);
            }
        } else {
            showMessage('error', 'Please fill in all required fields.', 0);
        }
    };

    const handleDelete = () => {
        showMessage(
            'confirmation',
            'Are you sure you want to delete this subject?',
            0,
            0,
            async () => {
                try {
                    await fetchWithAuth(`/api/subject/${subjectData.SubjectId}`, {
                        method: 'DELETE',
                    });
                    showMessage('success', 'Subject deleted successfully!', 0, 0);
                    if (onSubjectUpdated) {
                        onSubjectUpdated(subjectData, true);
                    }
                    onClose();
                } catch (error) {
                    showMessage('error', 'Error deleting subject: ' + error.message, 0, 0);
                }
            },
            () => {
                // Do nothing if the user cancels the deletion
            }
        );
    };

    const handleEditToggle = () => {
        if (isEditing && unsavedChanges) {
            if (window.confirm('You have unsaved changes. Do you want to discard them?')) {
                setIsEditing(false);
                setUnsavedChanges(false);
                setSubjectData(JSON.parse(JSON.stringify(originalSubjectData)));
            }
        } else {
            setIsEditing(!isEditing);
        }
    };

    const renderPersonalInfo = () => {
        if (!subjectData) return null;

        return (
            <div className="personal-info-container">
                <div className="info-group">
                    <h3>Demographics</h3>
                    <div className="form-row">
                        <label>
                            <span className="required-field">First Name:</span>
                            <input
                                type="text"
                                name="FirstName"
                                value={subjectData.FirstName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={50}
                                className={`${!isEditing ? 'read-only' : ''} ${errors.FirstName ? 'error' : ''}`}
                            />
                            {errors.FirstName && <span className="error-message">{errors.FirstName}</span>}
                        </label>
                        <label>
                            <span className="required-field">Last Name:</span>
                            <input
                                type="text"
                                name="LastName"
                                value={subjectData.LastName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={50}
                                className={`${!isEditing ? 'read-only' : ''} ${errors.LastName ? 'error' : ''}`}
                            />
                            {errors.LastName && <span className="error-message">{errors.LastName}</span>}
                        </label>
                    </div>
                    <div className="form-row">
                        <label>
                            Middle Initial:
                            <input
                                type="text"
                                name="MiddleInitial"
                                value={subjectData.MiddleInitial}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={1}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                        <label>
                            Date of Birth:
                            <input
                                type="date"
                                name="DateOfBirth"
                                value={subjectData.DateOfBirth || ''}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                    </div>
                    <div className="form-row">
                        <label>
                            Race:
                            <select
                                name="Race"
                                value={subjectData.Race}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={!isEditing ? 'read-only' : ''}
                            >
                                <option value="">Select...</option>
                                <option value="Caucasian">Caucasian</option>
                                <option value="African American">African American</option>
                                <option value="American Indian or Alaskan Native">American Indian or Alaskan Native</option>
                                <option value="Asian">Asian</option>
                                <option value="Native American or other Pacific Islander">Native American or other Pacific Islander</option>
                                <option value="Unknown">Unknown</option>
                                <option value="Other">Other</option>
                            </select>
                        </label>
                        <label>
                            Ethnicity:
                            <select
                                name="Ethnicity"
                                value={subjectData.Ethnicity}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={!isEditing ? 'read-only' : ''}
                            >
                                <option value="">Select...</option>
                                <option value="Hispanic or Latino">Hispanic or Latino</option>
                                <option value="Not Hispanic or Latino">Not Hispanic or Latino</option>
                            </select>
                        </label>
                        <label>
                            Sex:
                            <select
                                name="Sex"
                                value={subjectData.Sex}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={!isEditing ? 'read-only' : ''}
                            >
                                <option value="">Select...</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </label>
                    </div>
                    <div className="form-row">
                        <label>
                            Primary Language:
                            <input
                                type="text"
                                name="PrimaryLanguage"
                                value={subjectData.PrimaryLanguage}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={50}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                        <label>
                            Native Language:
                            <input
                                type="text"
                                name="NativeLanguage"
                                value={subjectData.NativeLanguage}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={50}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                    </div>
                </div>

                <div className="info-group">
                    <h3>Contact Info</h3>
                    <div className="form-row">
                        <label>
                            <span>Phone Number:</span>
                            <input
                                type="tel"
                                name="PhoneNumber"
                                value={subjectData.PhoneNumber}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={20}
                                className={`${!isEditing ? 'read-only' : ''} ${errors.PhoneNumber ? 'error' : ''}`}
                            />
                            {errors.PhoneNumber && <span className="error-message">{errors.PhoneNumber}</span>}
                        </label>
                        <label>
                            Email Address:
                            <input
                                type="email"
                                name="EmailAddress"
                                value={subjectData.EmailAddress}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={100}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                    </div>
                    <div className="form-row">
                        <label className="full-width">
                            Address:
                            <input
                                type="text"
                                name="Address"
                                value={subjectData.Address}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={200}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                    </div>
                    <div className="form-row">
                        <label className="full-width">
                            Emergency Contact:
                            <input
                                type="text"
                                name="EmergencyContact"
                                value={subjectData.EmergencyContact}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={100}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                    </div>
                    <div className="form-row">
                        <label className="full-width">
                            Legally Authorized Representative:
                            <input
                                type="text"
                                name="LegallyAuthorizedRepresentative"
                                value={subjectData.LegallyAuthorizedRepresentative}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={100}
                                className={!isEditing ? 'read-only' : ''}
                            />
                        </label>
                    </div>
                </div>

                <div className="info-group">
                    <h3>Study Info</h3>
                    <div className="form-row">
                        <label>
                            Protocol Number:
                            <select
                                name="ProtocolNumber"
                                value={subjectData.ProtocolNumber}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={!isEditing ? 'read-only' : ''}
                            >
                                <option value="">Select a protocol...</option>
                                {Array.isArray(protocols) && protocols.map(protocol => (
                                    <option key={protocol.ProtocolNumber} value={protocol.ProtocolNumber}>
                                        {protocol.ProtocolNumber}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            <span>Subject Number:</span>
                            <input
                                type="text"
                                name="SubjectNumber"
                                value={subjectData.SubjectNumber}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                maxLength={50}
                                className={`${!isEditing ? 'read-only' : ''} ${errors.SubjectNumber ? 'error' : ''}`}
                            />
                            {errors.SubjectNumber && <span className="error-message">{errors.SubjectNumber}</span>}
                        </label>
                    </div>
                </div>


                {/* Render Care Teams */}
                <div className="info-group full-width">
                    {renderCareTeams()}
                </div>
            </div>
        );
    };

    const renderSocialInfo = () => {
        if (!subjectData) return null;

        return (
            <>
                <div className="form-row">
                    <label>
                        Alcohol History:
                        <input
                            type="text"
                            name="AlcoholHistory"
                            value={subjectData.AlcoholHistory}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            maxLength={500}
                            className={!isEditing ? 'read-only' : ''}
                        />
                    </label>
                    <label>
                        Cigarette History:
                        <input
                            type="text"
                            name="CigaretteHistory"
                            value={subjectData.CigaretteHistory}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            maxLength={500}
                            className={!isEditing ? 'read-only' : ''}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label>
                        Gender:
                        <input
                            type="text"
                            name="Gender"
                            value={subjectData.Gender}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            maxLength={50}
                            className={!isEditing ? 'read-only' : ''}
                        />
                    </label>
                    <label>
                        Sexual Orientation:
                        <input
                            type="text"
                            name="SexualOrientation"
                            value={subjectData.SexualOrientation}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            maxLength={50}
                            className={!isEditing ? 'read-only' : ''}
                        />
                    </label>
                </div>
            </>
        );
    };

    const renderMedicalHistories = () => {
        if (!subjectData) return null;

        const allPressed = selectedTypes.length === 3;

        // Map over MedicalHistories to keep track of original index
        const filteredMedicalHistories = subjectData.MedicalHistories
            .map((history, index) => ({ history, index }))
            .filter(({ history }) => selectedTypes.includes(history.Type));

        return (
            <div className="array-inputs">
                <div className="medical-history-header">
                    <h3>Medical Histories</h3>
                    <div className="medical-history-filters">
                        <button
                            type="button"
                            className={`filter-button ${allPressed ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('ALL')}
                        >
                            All
                        </button>
                        <button
                            type="button"
                            className={`filter-button ${selectedTypes.includes('C') ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('C')}
                        >
                            Current Dx
                        </button>
                        <button
                            type="button"
                            className={`filter-button ${selectedTypes.includes('P') ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('P')}
                        >
                            Previous Dx
                        </button>
                        <button
                            type="button"
                            className={`filter-button ${selectedTypes.includes('F') ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('F')}
                        >
                            Family Dx
                        </button>
                    </div>
                </div>
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Condition</th>
                            <th>Diagnosis Date</th>
                            <th>Description</th>
                            <th>Tags (comma-separated)</th>
                            {isEditing && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMedicalHistories.map(({ history, index }) => (
                            <tr key={index}>
                                <td data-label="Type">
                                    <div className="medical-history-type-selector">
                                        <div
                                            className="type-circle"
                                            style={{
                                                backgroundColor: getTypeColor(history.Type || 'C'),
                                            }}
                                        ></div>
                                        <select
                                            name="Type"
                                            value={history.Type || 'C'}
                                            onChange={(e) => handleMedicalHistoryTypeChange(index, e.target.value)}
                                            disabled={!isEditing}
                                            className={!isEditing ? 'read-only' : ''}
                                        >
                                            <option value="C">Current Dx</option>
                                            <option value="P">Previous Dx</option>
                                            <option value="F">Family Dx</option>
                                        </select>
                                    </div>
                                </td>
                                <td data-label="Condition">
                                    <input
                                        type="text"
                                        name="Condition"
                                        value={history.Condition || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        placeholder="Condition"
                                        disabled={!isEditing}
                                        maxLength={100}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Diagnosis Date">
                                    <input
                                        type="date"
                                        name="DiagnosisDate"
                                        value={history.DiagnosisDate || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        disabled={!isEditing}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Description">
                                    <textarea
                                        name="Description"
                                        value={history.Description || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        placeholder="Description"
                                        disabled={!isEditing}
                                        maxLength={500}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Tags">
                                    <input
                                        type="text"
                                        name="Tags"
                                        value={
                                            history.Tags && Array.isArray(history.Tags.$values)
                                                ? history.Tags.$values.map(tag => tag.Tag).join(', ')
                                                : ''
                                        }
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        placeholder="Tags (comma-separated)"
                                        disabled={!isEditing}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                {isEditing && (
                                    <td data-label="Actions">
                                        <button type="button" onClick={() => removeArrayItem(index, 'MedicalHistories')}>Remove</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isEditing && (
                    <button type="button" onClick={() => addArrayItem('MedicalHistories')}>Add Medical History</button>
                )}
            </div>
        );
    };

    const renderMedications = () => {
        if (!subjectData) return null;

        return (
            <div className="array-inputs">
                <h3>Medications</h3>
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Dosage</th>
                            <th>Frequency</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            {isEditing && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {subjectData.Medications.map((medication, index) => (
                            <tr key={index}>
                                <td data-label="Name">
                                    <input
                                        type="text"
                                        name="Name"
                                        value={medication.Name || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                        placeholder="Medication Name"
                                        disabled={!isEditing}
                                        maxLength={100}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Dosage">
                                    <input
                                        type="text"
                                        name="Dosage"
                                        value={medication.Dosage || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                        placeholder="Dosage"
                                        disabled={!isEditing}
                                        maxLength={50}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Frequency">
                                    <input
                                        type="text"
                                        name="Frequency"
                                        value={medication.Frequency || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                        placeholder="Frequency"
                                        disabled={!isEditing}
                                        maxLength={50}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Start Date">
                                    <input
                                        type="date"
                                        name="StartDate"
                                        value={medication.StartDate || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                        disabled={!isEditing}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="End Date">
                                    <input
                                        type="date"
                                        name="EndDate"
                                        value={medication.EndDate || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                        disabled={!isEditing}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                {isEditing && (
                                    <td data-label="Actions">
                                        <button type="button" onClick={() => removeArrayItem(index, 'Medications')}>Remove</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isEditing && (
                    <button type="button" onClick={() => addArrayItem('Medications')}>Add Medication</button>
                )}
            </div>
        );
    };

    const renderNotes = () => {
        if (!subjectData) return null;

        return (
            <div className="array-inputs">
                <h3>Notes</h3>
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th>Content</th>
                            <th>Created</th>
                            <th>Updated</th>
                            <th>Created By</th>
                            <th>Last Updated By</th>
                            {isEditing && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {subjectData.Notes.map((note, index) => (
                            <tr key={index}>
                                <td data-label="Content">
                                    <textarea
                                        name="Content"
                                        value={note.Content || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'Notes')}
                                        placeholder="Note Content"
                                        disabled={!isEditing}
                                        maxLength={1000}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Created">
                                    {note.DateAdded || 'N/A'}
                                </td>
                                <td data-label="Updated">
                                    {note.DateUpdated || 'N/A'}
                                </td>
                                <td data-label="Created By">{note.CreatedByUserFullName || 'N/A'}</td>
                                <td data-label="Last Updated By">{note.LastUpdatedByUserFullName || 'N/A'}</td>
                                {isEditing && (
                                    <td data-label="Actions">
                                        <button type="button" onClick={() => removeArrayItem(index, 'Notes')}>Remove</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isEditing && (
                    <button type="button" onClick={() => addArrayItem('Notes')}>Add Note</button>
                )}
            </div>
        );
    };

    const renderCareTeams = () => {
        if (!subjectData) return null;

        return (
            <div className="array-inputs">
                <h3>Care Team</h3>
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Specialty</th>
                            <th>Phone</th>
                            <th>Fax</th>
                            {isEditing && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {subjectData.CareTeams.map((team, index) => (
                            <tr key={index}>
                                <td data-label="Name">
                                    <input
                                        type="text"
                                        name="Name"
                                        value={team.Name || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                        placeholder="Name"
                                        disabled={!isEditing}
                                        maxLength={100}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Specialty">
                                    <input
                                        type="text"
                                        name="Specialty"
                                        value={team.Specialty || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                        placeholder="Specialty"
                                        disabled={!isEditing}
                                        maxLength={100}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Phone">
                                    <input
                                        type="tel"
                                        name="Phone"
                                        value={team.Phone || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                        placeholder="Phone"
                                        disabled={!isEditing}
                                        maxLength={20}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                <td data-label="Fax">
                                    <input
                                        type="tel"
                                        name="Fax"
                                        value={team.Fax || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                        placeholder="Fax"
                                        disabled={!isEditing}
                                        maxLength={20}
                                        className={!isEditing ? 'read-only' : ''}
                                    />
                                </td>
                                {isEditing && (
                                    <td data-label="Actions">
                                        <button type="button" onClick={() => removeArrayItem(index, 'CareTeams')}>Remove</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isEditing && (
                    <button type="button" onClick={() => addArrayItem('CareTeams')}>Add Care Team Member</button>
                )}
            </div>
        );
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!subjectData) {
        return <div>No data available</div>;
    }

    return (
        <div className="add-subject-form">
            <div className="form-header">
                <div className="edit-actions">
                    {isEditing ? (
                        <img src={backIcon} alt="Close" onClick={handleEditToggle} />
                    ) : (
                        <img src={editIcon} alt="Edit" onClick={handleEditToggle} />
                    )}
                    <img src={deleteIcon} alt="Delete" onClick={handleDelete} />
                </div>
                <h2>Edit Subject</h2>
                <img
                    src={backIcon}
                    alt="Close"
                    className="back-icon-edit"
                    onClick={() => {
                        if (unsavedChanges) {
                            if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
                                onClose();
                            }
                        } else {
                            onClose();
                        }
                    }}
                />
            </div>
            <div className="tabs">
                <button className={activeTab === 'personalinfo' ? 'active' : ''} onClick={() => setActiveTab('personalinfo')}>Personal Info</button>
                <button className={activeTab === 'socialhx' ? 'active' : ''} onClick={() => setActiveTab('socialhx')}>Social Hx</button>
                <button className={activeTab === 'medicalhx' ? 'active' : ''} onClick={() => setActiveTab('medicalhx')}>Medical Hx</button>
                <button className={activeTab === 'medications' ? 'active' : ''} onClick={() => setActiveTab('medications')}>Medications/Allergies</button>
                <button className={activeTab === 'notes' ? 'active' : ''} onClick={() => setActiveTab('notes')}>Notes</button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="tab-content">
                    {activeTab === 'personalinfo' && renderPersonalInfo()}
                    {activeTab === 'socialhx' && renderSocialInfo()}
                    {activeTab === 'medicalhx' && renderMedicalHistories()}
                    {activeTab === 'medications' && renderMedications()}
                    {activeTab === 'notes' && renderNotes()}
                </div>
                {isEditing && (
                    <div className="form-buttons">
                        <button type="submit">Save Changes</button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default EditSubject;

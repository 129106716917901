import React from 'react';
import {
    format,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    isSameMonth,
    startOfWeek,
    endOfWeek,
    isSameDay,
    isToday,
    isWeekend,
    getWeeksInMonth
} from 'date-fns';
import { MoreHorizontal, Clock, User } from 'lucide-react';

const MonthView = ({ currentDate, visits, onVisitClick }) => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const calendarStart = startOfWeek(monthStart);
    const calendarEnd = endOfWeek(monthEnd);
    const weeksInMonth = getWeeksInMonth(currentDate);

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const calendarDays = eachDayOfInterval({
        start: calendarStart,
        end: calendarEnd
    });

    const visitsByDate = visits.reduce((acc, visit) => {
        const dateKey = format(new Date(visit.scheduledDateTime), 'yyyy-MM-dd');
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(visit);
        return acc;
    }, {});

    const getVisitsForDate = (date) => {
        const dateKey = format(date, 'yyyy-MM-dd');
        return visitsByDate[dateKey] || [];
    };

    const renderVisit = (visit) => {
        const startTime = format(new Date(visit.scheduledDateTime), 'h:mm a');
        const patientName = visit.patientName || 'Unnamed Patient';

        return (
            <div
                key={visit.visitId}
                className="month-view-visit"
                onClick={(e) => {
                    e.stopPropagation();
                    onVisitClick(visit);
                }}
            >
                <div className="visit-content">
                    <div className="visit-main-info">
                        <Clock size={12} className="visit-icon" />
                        <span className="visit-time">{startTime}</span>
                    </div>
                    <div className="visit-patient-info">
                        <User size={12} className="visit-icon" />
                        <span className="visit-patient">{patientName}</span>
                    </div>
                </div>
                <span className={`visit-status status-${visit.status.toLowerCase()}`}>
                    {visit.status}
                </span>
            </div>
        );
    };

    const renderDate = (date) => {
        const dateVisits = getVisitsForDate(date);
        const isCurrentMonth = isSameMonth(date, currentDate);
        const isWeekendDay = isWeekend(date);
        const className = `calendar-date${isCurrentMonth ? '' : ' other-month'}${isToday(date) ? ' today' : ''
            }${isWeekendDay ? ' weekend' : ''}`;

        const maxVisibleVisits = 3;
        const visibleVisits = dateVisits.slice(0, maxVisibleVisits);
        const remainingVisits = dateVisits.length - maxVisibleVisits;

        return (
            <div key={date.toString()} className={className}>
                <div className="date-header">
                    <div className="date-number-container">
                        <span className="date-number">{format(date, 'd')}</span>
                    </div>
                    {dateVisits.length > 0 && (
                        <span className="visit-count">
                            {dateVisits.length} {dateVisits.length === 1 ? 'visit' : 'visits'}
                        </span>
                    )}
                </div>
                <div className="visits-container">
                    {visibleVisits.map(visit => renderVisit(visit))}
                    {remainingVisits > 0 && (
                        <div className="more-visits" onClick={(e) => e.stopPropagation()}>
                            <MoreHorizontal size={14} />
                            <span>{remainingVisits} more</span>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="month-view">
            <div className="calendar-header">
                {daysOfWeek.map(day => (
                    <div key={day} className="weekday-header">
                        <span className="weekday-full">{day}</span>
                        <span className="weekday-short">{day.slice(0, 3)}</span>
                    </div>
                ))}
            </div>
            <div className={`calendar-grid weeks-${weeksInMonth}`}>
                {calendarDays.map(date => renderDate(date))}
            </div>
        </div>
    );
};

export default MonthView;

import React, { useState, useEffect } from 'react';
import './AddSubject.css';
import backIcon from '../../../../assets/icons/Close.png';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { formatDateOnly } from '../../../../utils/formatDateOnly';
import { useMessage } from '../../../../components/Message/MessageProvider';

const AddSubject = ({ onSave, onClose }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [activeTab, setActiveTab] = useState('personalinfo');
    const [protocols, setProtocols] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedTypes, setSelectedTypes] = useState(['C', 'P', 'F']);
    const [subjectData, setSubjectData] = useState({
        SubjectNumber: '',
        FirstName: '',
        MiddleInitial: '',
        LastName: '',
        DateOfBirth: '',
        Address: '',
        PhoneNumber: '',
        EmailAddress: '',
        Race: '',
        Ethnicity: '',
        EmergencyContact: '',
        ProtocolNumber: '',
        AlcoholHistory: '',
        CigaretteHistory: '',
        Sex: '',
        Gender: '',
        SexualOrientation: '',
        PrimaryLanguage: '',
        NativeLanguage: '',
        LegallyAuthorizedRepresentative: '',
        MedicalHistories: [],
        Medications: [],
        Notes: [],
        CareTeams: []
    });

    useEffect(() => {
        fetchProtocols();
    }, []);

    const fetchProtocols = async () => {
        try {
            const response = await fetchWithAuth('/api/protocol');
            const protocolsArray = response.$values || [];
            setProtocols(protocolsArray);
        } catch (error) {
            console.error('Error fetching protocols:', error);
            showMessage('error', 'Error fetching protocols: ' + error.message, 0);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSubjectData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleArrayInputChange = (e, index, arrayName) => {
        const { name, value } = e.target;
        setSubjectData(prevData => {
            const newArray = [...prevData[arrayName]];
            if (name === 'Tags') {
                const newTags = value.split(',').map(tag => ({ Tag: tag.trim() }));
                newArray[index] = {
                    ...newArray[index],
                    Tags: {
                        $values: newTags
                    }
                };
            } else {
                newArray[index] = {
                    ...newArray[index],
                    [name]: value
                };
            }
            return { ...prevData, [arrayName]: newArray };
        });
    };

    const handleTypeFilterClick = (type) => {
        if (type === 'ALL') {
            if (selectedTypes.length === 3) {
                // All types are selected, unselect all
                setSelectedTypes([]);
            } else {
                // Not all types are selected, select all
                setSelectedTypes(['C', 'P', 'F']);
            }
        } else {
            let newSelectedTypes;
            if (selectedTypes.includes(type)) {
                // Remove the type
                newSelectedTypes = selectedTypes.filter(t => t !== type);
            } else {
                // Add the type
                newSelectedTypes = [...selectedTypes, type];
            }
            setSelectedTypes(newSelectedTypes);
        }
    };

    const handleMedicalHistoryTypeChange = (index, value) => {
        setSubjectData(prevData => {
            const newMedicalHistories = [...prevData.MedicalHistories];
            newMedicalHistories[index] = {
                ...newMedicalHistories[index],
                Type: value
            };
            return { ...prevData, MedicalHistories: newMedicalHistories };
        });
    };

    const getTypeColor = (type) => {
        switch (type) {
            case 'F':
                return '#00A02F'; // Family Dx
            case 'P':
                return '#F8C706'; // Previous Dx
            case 'C':
            default:
                return '#1359A1'; // Current Dx
        }
    };

    const addArrayItem = (arrayName) => {
        const currentDate = formatDateOnly(new Date());

        setSubjectData(prevData => ({
            ...prevData,
            [arrayName]: [
                ...prevData[arrayName],
                arrayName === 'Notes' ? {
                    Content: '',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : arrayName === 'Medications' ? {
                    Name: '',
                    Dosage: '',
                    Frequency: '',
                    StartDate: null,
                    EndDate: null,
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : arrayName === 'MedicalHistories' ? {
                    Condition: '',
                    DiagnosisDate: null,
                    Description: '',
                    Tags: [],
                    Type: 'C',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : arrayName === 'CareTeams' ? {
                    Name: '',
                    Specialty: '',
                    Phone: '',
                    Fax: '',
                    DateAdded: currentDate,
                    DateUpdated: currentDate,
                    IsDeleted: false
                } : {}
            ]
        }));
    };

    const removeArrayItem = (index, arrayName) => {
        setSubjectData(prevData => ({
            ...prevData,
            [arrayName]: prevData[arrayName].filter((_, i) => i !== index)
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!subjectData.FirstName?.trim()) {
            newErrors.FirstName = 'First Name is required.';
        }
        if (!subjectData.LastName?.trim()) {
            newErrors.LastName = 'Last Name is required.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                // Transform tags from strings to objects
                const transformedSubjectData = {
                    ...subjectData,
                    DateOfBirth: subjectData.DateOfBirth || null,
                    MedicalHistories: subjectData.MedicalHistories.map(history => ({
                        ...history,
                        Tags: Array.isArray(history.Tags?.$values)
                            ? history.Tags.$values.map(tag => ({
                                Tag: tag.Tag
                            }))
                            : []
                    }))
                };

                const payload = {
                    subjectDTO: transformedSubjectData
                };

                const response = await fetchWithAuth('/api/subject', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                });

                if (response && typeof response === 'object') {
                    if (response instanceof Response) {
                        if (response.ok) {
                            const data = await response.json();
                            if (data && data.SubjectId) {
                                onSave(data);
                                showMessage('success', 'Subject added successfully!', 3000);
                            } else {
                                throw new Error('Invalid response from server');
                            }
                        } else {
                            const errorText = await response.text();
                            throw new Error(errorText || 'Unknown error occurred');
                        }
                    } else {
                        if (response && response.SubjectId) {
                            onSave(response);
                            showMessage('success', 'Subject added successfully!', 3000);
                        } else {
                            throw new Error('Invalid response from server');
                        }
                    }
                } else {
                    console.error('Unexpected response:', response);
                    throw new Error('Unexpected response from server');
                }
            } catch (error) {
                console.error('Error in handleSubmit:', error);
                showMessage('error', 'Error adding subject: ' + (error.message || 'Unknown error'), 0);
                if (error.message.includes('Subject Number')) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        SubjectNumber: error.message
                    }));
                }
            }
        } else {
            showMessage('error', 'Please fill in all required fields.', 0);
        }
    };

    const renderPersonalInfo = () => (
        <div className="personal-info-container">
            <div className="info-group">
                <h3>Demographics</h3>
                <div className="form-row">
                    <label>
                        <span className="required-field">First Name:</span>
                        <input
                            type="text"
                            name="FirstName"
                            value={subjectData.FirstName}
                            onChange={handleInputChange}
                            maxLength={50}
                            className={errors.FirstName ? 'error' : ''}
                        />
                        {errors.FirstName && <span className="error-message">{errors.FirstName}</span>}
                    </label>
                    <label>
                        <span className="required-field">Last Name:</span>
                        <input
                            type="text"
                            name="LastName"
                            value={subjectData.LastName}
                            onChange={handleInputChange}
                            maxLength={50}
                            className={errors.LastName ? 'error' : ''}
                        />
                        {errors.LastName && <span className="error-message">{errors.LastName}</span>}
                    </label>
                </div>
                <div className="form-row">
                    <label>
                        Middle Initial:
                        <input
                            type="text"
                            name="MiddleInitial"
                            value={subjectData.MiddleInitial}
                            onChange={handleInputChange}
                            maxLength={1}
                        />
                    </label>
                    <label>
                        Date of Birth:
                        <input
                            type="date"
                            name="DateOfBirth"
                            value={subjectData.DateOfBirth || ''}
                            onChange={handleInputChange}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label>
                        Race:
                        <select
                            name="Race"
                            value={subjectData.Race}
                            onChange={handleInputChange}
                        >
                            <option value="">Select...</option>
                            <option value="Caucasian">Caucasian</option>
                            <option value="African American">African American</option>
                            <option value="American Indian or Alaskan Native">American Indian or Alaskan Native</option>
                            <option value="Asian">Asian</option>
                            <option value="Native American or other Pacific Islander">Native American or other Pacific Islander</option>
                            <option value="Unknown">Unknown</option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                    <label>
                        Ethnicity:
                        <select
                            name="Ethnicity"
                            value={subjectData.Ethnicity}
                            onChange={handleInputChange}
                        >
                            <option value="">Select...</option>
                            <option value="Hispanic or Latino">Hispanic or Latino</option>
                            <option value="Not Hispanic or Latino">Not Hispanic or Latino</option>
                        </select>
                    </label>
                    <label>
                        Sex:
                        <select
                            name="Sex"
                            value={subjectData.Sex}
                            onChange={handleInputChange}
                        >
                            <option value="">Select...</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                </div>
                <div className="form-row">
                    <label>
                        Primary Language:
                        <input
                            type="text"
                            name="PrimaryLanguage"
                            value={subjectData.PrimaryLanguage}
                            onChange={handleInputChange}
                            maxLength={50}
                        />
                    </label>
                    <label>
                        Native Language:
                        <input
                            type="text"
                            name="NativeLanguage"
                            value={subjectData.NativeLanguage}
                            onChange={handleInputChange}
                            maxLength={50}
                        />
                    </label>
                </div>
            </div>

            <div className="info-group">
                <h3>Contact Info</h3>
                <div className="form-row">
                    <label>
                        <span>Phone Number:</span>
                        <input
                            type="tel"
                            name="PhoneNumber"
                            value={subjectData.PhoneNumber}
                            onChange={handleInputChange}
                            maxLength={20}
                            className={errors.PhoneNumber ? 'error' : ''}
                        />
                        {errors.PhoneNumber && <span className="error-message">{errors.PhoneNumber}</span>}
                    </label>
                    <label>
                        Email Address:
                        <input
                            type="email"
                            name="EmailAddress"
                            value={subjectData.EmailAddress}
                            onChange={handleInputChange}
                            maxLength={100}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="full-width">
                        Address:
                        <input
                            type="text"
                            name="Address"
                            value={subjectData.Address}
                            onChange={handleInputChange}
                            maxLength={200}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="full-width">
                        Emergency Contact:
                        <input
                            type="text"
                            name="EmergencyContact"
                            value={subjectData.EmergencyContact}
                            onChange={handleInputChange}
                            maxLength={100}
                        />
                    </label>
                </div>
                <div className="form-row">
                    <label className="full-width">
                        Legally Authorized Representative:
                        <input
                            type="text"
                            name="LegallyAuthorizedRepresentative"
                            value={subjectData.LegallyAuthorizedRepresentative}
                            onChange={handleInputChange}
                            maxLength={100}
                        />
                    </label>
                </div>
            </div>

            <div className="info-group">
                <h3>Study Info</h3>
                <div className="form-row">
                    <label>
                        Protocol Number:
                        <select
                            name="ProtocolNumber"
                            value={subjectData.ProtocolNumber}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a protocol...</option>
                            {Array.isArray(protocols) && protocols.map(protocol => (
                                <option key={protocol.ProtocolNumber} value={protocol.ProtocolNumber}>
                                    {protocol.ProtocolNumber}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <span>Subject Number:</span>
                        <input
                            type="text"
                            name="SubjectNumber"
                            value={subjectData.SubjectNumber}
                            onChange={handleInputChange}
                            maxLength={50}
                            className={errors.SubjectNumber ? 'error' : ''}
                        />
                        {errors.SubjectNumber && <span className="error-message">{errors.SubjectNumber}</span>}
                    </label>
                </div>
            </div>

            {/* Render Care Teams */}
            <div className="info-group full-width">
                {renderCareTeams()}
            </div>
        </div>
    );

    const renderSocialInfo = () => (
        <>
            <div className="form-row">
                <label>
                    Alcohol History:
                    <input
                        type="text"
                        name="AlcoholHistory"
                        value={subjectData.AlcoholHistory}
                        onChange={handleInputChange}
                        maxLength={500}
                    />
                </label>
                <label>
                    Cigarette History:
                    <input
                        type="text"
                        name="CigaretteHistory"
                        value={subjectData.CigaretteHistory}
                        onChange={handleInputChange}
                        maxLength={500}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Gender:
                    <input
                        type="text"
                        name="Gender"
                        value={subjectData.Gender}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Sexual Orientation:
                    <input
                        type="text"
                        name="SexualOrientation"
                        value={subjectData.SexualOrientation}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
            </div>
        </>
    );

    const renderMedicalHistories = () => {
        const allPressed = selectedTypes.length === 3;

        // Map over MedicalHistories to keep track of original index
        const filteredMedicalHistories = subjectData.MedicalHistories
            .map((history, index) => ({ history, index }))
            .filter(({ history }) => selectedTypes.includes(history.Type));

        return (
            <div className="array-inputs">
                <div className="medical-history-header">
                    <h3>Medical Histories</h3>
                    <div className="medical-history-filters">
                        <button
                            type="button"
                            className={`filter-button ${allPressed ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('ALL')}
                        >
                            All
                        </button>
                        <button
                            type="button"
                            className={`filter-button ${selectedTypes.includes('C') ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('C')}
                        >
                            Current Dx
                        </button>
                        <button
                            type="button"
                            className={`filter-button ${selectedTypes.includes('P') ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('P')}
                        >
                            Previous Dx
                        </button>
                        <button
                            type="button"
                            className={`filter-button ${selectedTypes.includes('F') ? 'pressed' : ''}`}
                            onClick={() => handleTypeFilterClick('F')}
                        >
                            Family Dx
                        </button>
                    </div>
                </div>
                <table className="responsive-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Condition</th>
                            <th>Diagnosis Date</th>
                            <th>Description</th>
                            <th>Tags (comma-separated)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMedicalHistories.map(({ history, index }) => (
                            <tr key={index}>
                                <td data-label="Type">
                                    <div className="medical-history-type-selector">
                                        <div
                                            className="type-circle"
                                            style={{
                                                backgroundColor: getTypeColor(history.Type || 'C'),
                                            }}
                                        ></div>
                                        <select
                                            name="Type"
                                            value={history.Type || 'C'}
                                            onChange={(e) => handleMedicalHistoryTypeChange(index, e.target.value)}
                                        >
                                            <option value="C">Current Dx</option>
                                            <option value="P">Previous Dx</option>
                                            <option value="F">Family Dx</option>
                                        </select>
                                    </div>
                                </td>
                                <td data-label="Condition">
                                    <input
                                        type="text"
                                        name="Condition"
                                        value={history.Condition || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        placeholder="Condition"
                                        maxLength={100}
                                    />
                                </td>
                                <td data-label="Diagnosis Date">
                                    <input
                                        type="date"
                                        name="DiagnosisDate"
                                        value={history.DiagnosisDate || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                    />
                                </td>
                                <td data-label="Description">
                                    <textarea
                                        name="Description"
                                        value={history.Description || ''}
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        placeholder="Description"
                                        maxLength={500}
                                    />
                                </td>
                                <td data-label="Tags">
                                    <input
                                        type="text"
                                        name="Tags"
                                        value={
                                            history.Tags && Array.isArray(history.Tags.$values)
                                                ? history.Tags.$values.map(tag => tag.Tag).join(', ')
                                                : ''
                                        }
                                        onChange={(e) => handleArrayInputChange(e, index, 'MedicalHistories')}
                                        placeholder="Tags (comma-separated)"
                                    />
                                </td>
                                <td data-label="Actions">
                                    <button type="button" onClick={() => removeArrayItem(index, 'MedicalHistories')}>Remove</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" onClick={() => addArrayItem('MedicalHistories')}>Add Medical History</button>
            </div>
        );
    };


    const renderMedications = () => (
        <div className="array-inputs">
            <h3>Medications</h3>
            <table className="responsive-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subjectData.Medications.map((medication, index) => (
                        <tr key={index}>
                            <td data-label="Name">
                                <input
                                    type="text"
                                    name="Name"
                                    value={medication.Name || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                    placeholder="Medication Name"
                                    maxLength={100}
                                />
                            </td>
                            <td data-label="Dosage">
                                <input
                                    type="text"
                                    name="Dosage"
                                    value={medication.Dosage || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                    placeholder="Dosage"
                                    maxLength={50}
                                />
                            </td>
                            <td data-label="Frequency">
                                <input
                                    type="text"
                                    name="Frequency"
                                    value={medication.Frequency || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                    placeholder="Frequency"
                                    maxLength={50}
                                />
                            </td>
                            <td data-label="Start Date">
                                <input
                                    type="date"
                                    name="StartDate"
                                    value={medication.StartDate ? new Date(medication.StartDate).toISOString().split('T')[0] : ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                />
                            </td>
                            <td data-label="End Date">
                                <input
                                    type="date"
                                    name="EndDate"
                                    value={medication.EndDate ? new Date(medication.EndDate).toISOString().split('T')[0] : ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'Medications')}
                                />
                            </td>
                            <td data-label="Actions">
                                <button type="button" onClick={() => removeArrayItem(index, 'Medications')}>Remove</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={() => addArrayItem('Medications')}>Add Medication</button>
        </div>
    );

    const renderNotes = () => (
        <div className="array-inputs">
            <h3>Notes</h3>
            <table className="responsive-table">
                <thead>
                    <tr>
                        <th>Content</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subjectData.Notes.map((note, index) => (
                        <tr key={index}>
                            <td data-label="Content">
                                <textarea
                                    name="Content"
                                    value={note.Content || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'Notes')}
                                    placeholder="Note Content"
                                    maxLength={1000}
                                />
                            </td>
                            <td data-label="Actions">
                                <button type="button" onClick={() => removeArrayItem(index, 'Notes')}>Remove</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={() => addArrayItem('Notes')}>Add Note</button>
        </div>
    );

    const renderCareTeams = () => (
        <div className="array-inputs">
            <h3>Care Team</h3>
            <table className="responsive-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Specialty</th>
                        <th>Phone</th>
                        <th>Fax</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {subjectData.CareTeams.map((team, index) => (
                        <tr key={index}>
                            <td data-label="Name">
                                <input
                                    type="text"
                                    name="Name"
                                    value={team.Name || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                    placeholder="Name"
                                    maxLength={100}
                                />
                            </td>
                            <td data-label="Specialty">
                                <input
                                    type="text"
                                    name="Specialty"
                                    value={team.Specialty || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                    placeholder="Specialty"
                                    maxLength={100}
                                />
                            </td>
                            <td data-label="Phone">
                                <input
                                    type="tel"
                                    name="Phone"
                                    value={team.Phone || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                    placeholder="Phone"
                                    maxLength={20}
                                />
                            </td>
                            <td data-label="Fax">
                                <input
                                    type="tel"
                                    name="Fax"
                                    value={team.Fax || ''}
                                    onChange={(e) => handleArrayInputChange(e, index, 'CareTeams')}
                                    placeholder="Fax"
                                    maxLength={20}
                                />
                            </td>
                            <td data-label="Actions">
                                <button type="button" onClick={() => removeArrayItem(index, 'CareTeams')}>Remove</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={() => addArrayItem('CareTeams')}>Add Care Team Member</button>
        </div>
    );

    return (
        <div className="add-subject-form">
            <div className="form-header">
                <h2>Add New Subject</h2>
                <img src={backIcon} alt="Close" className="back-icon-add" onClick={onClose} />
            </div>
            <div className="tabs">
                <button className={activeTab === 'personalinfo' ? 'active' : ''} onClick={() => setActiveTab('personalinfo')}>Personal Info</button>
                <button className={activeTab === 'socialhx' ? 'active' : ''} onClick={() => setActiveTab('socialhx')}>Social Hx</button>
                <button className={activeTab === 'medicalhx' ? 'active' : ''} onClick={() => setActiveTab('medicalhx')}>Medical Hx</button>
                <button className={activeTab === 'medications' ? 'active' : ''} onClick={() => setActiveTab('medications')}>Medications/Allergies</button>
                <button className={activeTab === 'notes' ? 'active' : ''} onClick={() => setActiveTab('notes')}>Notes</button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="tab-content">
                    {activeTab === 'personalinfo' && renderPersonalInfo()}
                    {activeTab === 'socialhx' && renderSocialInfo()}
                    {activeTab === 'medicalhx' && renderMedicalHistories()}
                    {activeTab === 'medications' && renderMedications()}
                    {activeTab === 'notes' && renderNotes()}
                </div>
                <div className="form-buttons">
                    <button type="submit">Add Subject</button>
                </div>
            </form>
        </div>
    );
};

export default AddSubject;

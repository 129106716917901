// Cerebro/ClientApp/src/pages/Dashboard/Admin/Admin.js

import React, { useState, useEffect } from 'react';
import { UserIcon, BookOpenIcon, DollarSignIcon } from 'lucide-react';
import useFetchWithAuth from '../../../utils/useFetchWithAuth';
import { useMessage } from '../../../components/Message/MessageProvider';
import './Admin.css';

const Admin = () => {
    const { fetchWithAuth, putWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [activeTab, setActiveTab] = useState('myRoles');
    const [myRoles, setMyRoles] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editedRoles, setEditedRoles] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchMyRoles();
        fetchUsers();
    }, []);

    const fetchMyRoles = async () => {
        try {
            const result = await fetchWithAuth('/api/userroles');
            setMyRoles(result);
        } catch (error) {
            showMessage('error', `Error fetching your roles: ${error.message}`);
        }
    };

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const result = await fetchWithAuth('/api/userroles/all');
            if (result && result.$values && Array.isArray(result.$values)) {
                setUsers(result.$values);
            } else if (Array.isArray(result)) {
                setUsers(result);
            } else {
                console.error('Expected an array of users or an object with $values array, but got:', result);
                setUsers([]);
            }
        } catch (error) {
            showMessage('error', `Error fetching users: ${error.message}`);
            setUsers([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserSelect = (userId) => {
        const user = users.find(u => u.UserId === userId);
        if (user) {
            setSelectedUser(user);
            setEditedRoles(user.Roles || {});
            setIsEditing(false);
        } else {
            console.error('Selected user not found:', userId);
            showMessage('error', 'Selected user not found');
        }
    };

    const handleRoleToggle = (role) => {
        setEditedRoles(prev => ({ ...prev, [role]: !prev[role] }));
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            await putWithAuth(`/api/userroles/${selectedUser.UserId}`, editedRoles);
            showMessage('success', 'Roles updated successfully.');
            setIsEditing(false);
            fetchUsers();
            setShowConfirmModal(false);
        } catch (error) {
            showMessage('error', `Error updating roles: ${error.message}`);
        }
    };

    const resetForm = () => {
        if (selectedUser) {
            setEditedRoles(selectedUser.Roles || {});
            setIsEditing(false);
        }
    };

    const transformRoleName = (role) => {
        if (role === 'IsAdmin') return 'Admin';
        if (role === 'InvestigationalProduct') return 'Investigational Product';
        return role.replace(/([A-Z])/g, ' $1').trim(); // Add spaces before capital letters
    };

    const renderRoleCard = (role, isEnabled) => (
        <div className={`role-card ${isEnabled ? 'enabled' : ''}`} key={role}>
            <UserIcon className="role-icon" />
            <h3>{role}</h3>
            {activeTab === 'userRoles' && (
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isEnabled}
                        onChange={() => handleRoleToggle(role)}
                    />
                    <span className="slider"></span>
                </label>
            )}
        </div>
    );

    return (
        <div className="admin-layout">
            <aside className="sidebar">
                <nav>
                    <ul>
                        <li className="active"><UserIcon /> Role Management</li>
                        <li><BookOpenIcon /> Study Management</li>
                        <li><DollarSignIcon /> Finances</li>
                    </ul>
                </nav>
            </aside>
            <main className="main-content">
                <header>
                    <h1>Admin Dashboard &gt; Role Management</h1>
                </header>
                <div className="tabs">
                    <button
                        className={activeTab === 'myRoles' ? 'active' : ''}
                        onClick={() => setActiveTab('myRoles')}
                    >
                        My Roles
                    </button>
                    <button
                        className={activeTab === 'userRoles' ? 'active' : ''}
                        onClick={() => setActiveTab('userRoles')}
                    >
                        User Roles
                    </button>
                </div>

                {activeTab === 'myRoles' && myRoles && (
                    <div className="roles-grid">
                        {Object.entries(myRoles).map(([role, isEnabled]) => {
                            if (role !== '$id' && role !== 'UserId') {
                                return renderRoleCard(transformRoleName(role), isEnabled);
                            }
                            return null;
                        })}
                    </div>
                )}

                {activeTab === 'userRoles' && (
                    <div className="user-roles">
                        {isLoading ? (
                            <p>Loading users...</p>
                        ) : users.length > 0 ? (
                            <>
                                <select
                                    value={selectedUser ? selectedUser.UserId : ''}
                                    onChange={(e) => handleUserSelect(e.target.value)}
                                >
                                    <option value="" disabled>Select a user</option>
                                    {users.map(u => (
                                        <option key={u.UserId} value={u.UserId}>
                                            {`${u.FullName} (${u.Username})`}
                                        </option>
                                    ))}
                                </select>
                                {selectedUser && (
                                    <>
                                        <div className="roles-list">
                                            {Object.entries(editedRoles).map(([role, isEnabled]) => {
                                                if (role !== '$id' && role !== 'UserId') {
                                                    return (
                                                        <label key={role} className="role-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                checked={isEnabled}
                                                                onChange={() => handleRoleToggle(role)}
                                                            />
                                                            {transformRoleName(role)}
                                                        </label>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                        <div className="button-group">
                                            <button
                                                className="save-button"
                                                onClick={handleSave}
                                                disabled={!isEditing}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <p>No users found.</p>
                        )}
                    </div>
                )}

                {showConfirmModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Confirm Changes</h2>
                            <p>Are you sure you want to save these changes?</p>
                            <button onClick={handleSave}>Yes, Save Changes</button>
                            <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

export default Admin;
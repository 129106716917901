import React, { useState } from 'react';
import { useMessage } from '../../../../../components/Message/MessageProvider';
import useFetchWithAuth from '../../../../../utils/useFetchWithAuth';
import backIcon from '../../../../../assets/icons/Back.png';  // Import back icon
import './AddSponsor.css';

const AddSponsor = ({ onClose, onSave }) => {
    const [sponsorName, setSponsorName] = useState('');
    const { showMessage } = useMessage();
    const { fetchWithAuth } = useFetchWithAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!sponsorName) {
            showMessage('error', 'Sponsor Name is required.', 0, 0);
            return;
        }

        try {
            const responseData = await fetchWithAuth('/api/Sponsor', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sponsorName })
            });

            if (responseData && responseData.SponsorName) {
                showMessage('success', 'Sponsor added successfully!', 0, 0, () => {
                    onSave();
                    onClose();
                });
            } else {
                showMessage('error', 'Failed to add sponsor.', 0, 0);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while adding the sponsor.', 0, 0);
        }
    };

    const handleBackClick = () => {
        onClose();
    };

    return (
        <div className="add-sponsor-popup">
            <img src={backIcon} alt="Back" className="back-icon" onClick={handleBackClick} />  {/* Add back icon */}
            <h2>Please enter a new Sponsor Name</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={sponsorName}
                    onChange={(e) => setSponsorName(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddSponsor;

// src/components/api-authorization/ApiAuthorizationRoutes.js

import React from 'react';
import LoginPage from '../../pages/Login/LoginPage';
import { ApplicationPaths, LoginActions } from './ApiAuthorizationConstants';

const ApiAuthorizationRoutes = [
    {
        path: ApplicationPaths.Login,
        element: <LoginPage action={LoginActions.Login} />
    },
    {
        path: ApplicationPaths.LoginFailed,
        element: <LoginPage action={LoginActions.LoginFailed} />
    },
    {
        path: ApplicationPaths.LoginCallback,
        element: <LoginPage action={LoginActions.LoginCallback} />
    },
    {
        path: ApplicationPaths.Profile,
        element: <LoginPage action={LoginActions.Profile} />
    },
    {
        path: ApplicationPaths.Register,
        element: <LoginPage action={LoginActions.Register} />
    }
];

export default ApiAuthorizationRoutes;

// Cerebro/ClientApp/src/pages/Dashboard/Subjects/Subjects.js

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import './Subjects.css';
import AddSubject from './Dialog/AddSubject';
import EditSubject from './Dialog/EditSubject';
import ImportSubject from './Dialog/ImportSubject';
import useFetchWithAuth from '../../../utils/useFetchWithAuth';
import { useMessage } from '../../../components/Message/MessageProvider';
import { useSubjectSearch } from './Dialog/useSubjectSearch';

const FilterInput = ({ value, onChange }) => {
    return (
        <input
            type="text"
            style={{
                padding: '8px',
                margin: '5px 0',
                border: '2px solid #ccc',
                borderRadius: '14px',
                fontSize: '16px',
                width: '100%' 
            }}
            className="filter-input"
            placeholder="Search subjects..."
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

const Subjects = () => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);
    const [showEditSubjectModal, setShowEditSubjectModal] = useState(false);
    const [showImportSubjectsModal, setShowImportSubjectsModal] = useState(false);

    const filteredSubjects = useSubjectSearch(data, searchTerm);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const result = await fetchWithAuth('/api/subject');
            let newData = result && result.$values ? result.$values : [];

            // Filter out deleted subjects
            newData = newData.filter(subject => !subject.IsDeleted);

            setData(newData);
        } catch (error) {
            showMessage('error', 'Error fetching data from server.', 0, 0);
            setData([]);
        } finally {
            setIsLoading(false);
        }
    }, [fetchWithAuth, showMessage]);

    const handleRowClick = useCallback(async (subject) => {
        try {
            const result = await fetchWithAuth(`/api/subject/${subject.SubjectId}`);
            if (result) {
                // Ensure related entities are arrays
                result.MedicalHistories = Array.isArray(result.MedicalHistories) ? result.MedicalHistories :
                    (result.MedicalHistories && result.MedicalHistories.$values) || [];
                result.Medications = Array.isArray(result.Medications) ? result.Medications :
                    (result.Medications && result.Medications.$values) || [];
                result.Notes = Array.isArray(result.Notes) ? result.Notes :
                    (result.Notes && result.Notes.$values) || [];
                result.CareTeams = Array.isArray(result.CareTeams) ? result.CareTeams :
                    (result.CareTeams && result.CareTeams.$values) || [];

                setSelectedSubject(result);
                setShowEditSubjectModal(true);
            } else {
                throw new Error("Failed to fetch subject details");
            }
        } catch (error) {
            console.error("Error fetching subject details:", error);
            showMessage('error', 'Error fetching subject details: ' + error.message, 0, 0);
        }
    }, [fetchWithAuth, showMessage]);

    const handleOpenAddSubject = () => {
        setShowAddSubjectModal(true);
    };

    const handleCloseAddSubject = () => {
        setShowAddSubjectModal(false);
    };

    const handleCloseEditSubject = () => {
        setShowEditSubjectModal(false);
        setSelectedSubject(null);
    };

    const handleAddSubjectSuccess = async (newSubject) => {
        await fetchData();
        showMessage('success', 'Subject added successfully!', 3000);
        setShowAddSubjectModal(false);
    };

    const handleOpenImportSubjects = () => {
        setShowImportSubjectsModal(true);
    };

    const handleCloseImportSubjects = () => {
        setShowImportSubjectsModal(false);
    };

    const handleImportSuccess = async (importedSubject) => {
        try {
            await fetchData();
            showMessage('success', 'Subject imported successfully!', 3000);
        } catch (error) {
            showMessage('error', 'Error importing subject: ' + error.message);
        }
    };

    const handleSubjectUpdated = useCallback((updatedSubject, isDeleted = false) => {
        if (!updatedSubject) {
            console.error('Updated subject is undefined');
            return;
        }
        if (isDeleted) {
            // If the subject was deleted, remove it from the local state
            setData(prevData => prevData.filter(subject => subject.SubjectId !== updatedSubject.SubjectId));
            // Close the modal only if the subject was deleted
            setShowEditSubjectModal(false);
        } else {
            // If the subject was updated, replace it in the local state
            setData(prevData => prevData.map(subject =>
                subject.SubjectId === updatedSubject.SubjectId ? updatedSubject : subject
            ));
        }
    }, []);

    const columns = useMemo(() => [
        {
            header: 'Name',
            cell: (info) => {
                const firstName = info.row.original.FirstName || '';
                const lastName = info.row.original.LastName || '';
                return firstName || lastName ? `${firstName} ${lastName}`.trim() : 'N/A';
            },
            accessorKey: 'FirstName',
        },
        {
            header: 'DOB/Age',
            cell: (info) => {
                const dob = info.row.original.DateOfBirth;
                if (!dob) return 'N/A';
                const dobDate = new Date(dob);
                if (isNaN(dobDate.getTime())) return 'Invalid Date';
                const age = new Date().getFullYear() - dobDate.getFullYear();
                return `${dobDate.toLocaleDateString()} (${age})`;
            },
            accessorKey: 'DateOfBirth',
        },
        {
            header: 'Subject Number',
            cell: (info) => info.getValue() || 'N/A',
            accessorKey: 'SubjectNumber',
        },
        {
            header: 'Phone Number',
            cell: (info) => info.getValue() || 'N/A',
            accessorKey: 'PhoneNumber',
        },
        {
            header: 'Email Address',
            cell: (info) => info.getValue() || 'N/A',
            accessorKey: 'EmailAddress',
        }
    ], []);

    const table = useReactTable({
        data: filteredSubjects,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="subjects-wrapper">
            <h1 className="page-title">Subjects</h1>
            <div className="top-row">
                <div className="search-container">
                    <FilterInput value={searchTerm} onChange={setSearchTerm} />
                </div>
                <button className="add-subject-button" onClick={handleOpenAddSubject}>
                    Add a Subject
                </button>
                <button className="import-subject-button" onClick={handleOpenImportSubjects}>
                    Import Subject
                </button>
            </div>
            <div className="data-table-container" style={{ position: 'relative' }}>
                {isLoading ? (
                    <p>Loading subjects...</p>
                ) : (
                    <div className="data-table-wrapper">
                        <table className="data-table">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id} onClick={() => handleRowClick(row.original)}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {showAddSubjectModal && (
                <div className="modal-overlay">
                    <AddSubject
                        onSave={handleAddSubjectSuccess}
                        onClose={handleCloseAddSubject}
                    />
                </div>
            )}
            {showEditSubjectModal && selectedSubject && (
                <div className="modal-overlay">
                    <EditSubject
                        subject={selectedSubject}
                        onClose={handleCloseEditSubject}
                        onSubjectUpdated={handleSubjectUpdated}
                    />
                </div>
            )}
            {showImportSubjectsModal && (
                <div className="modal-overlay">
                    <ImportSubject
                        onClose={handleCloseImportSubjects}
                        onImportSuccess={handleImportSuccess}
                    />
                </div>
            )}
        </div>
    );
};

export default Subjects;
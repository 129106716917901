// index.js

import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import MessageProvider from './components/Message/MessageProvider';

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href') ?? '/';
const rootElement = document.getElementById('app');
const root = createRoot(rootElement);

root.render(

    <BrowserRouter basename={baseUrl}>
        <AuthProvider>
            <MessageProvider>
                <App />
            </MessageProvider>
        </AuthProvider>
    </BrowserRouter>

);


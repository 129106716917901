// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/PatientSearch.js

import React, { useState, useEffect, useRef } from 'react';
import { Search, Loader } from 'lucide-react';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { formatPhoneNumber } from '../../../../utils/formatters';
import './PatientSearch.css';

const PatientSearch = ({ onSelect }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('lastName');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const searchRef = useRef(null);
    const searchTimeout = useRef(null);

    useEffect(() => {
        // Add click outside listener
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        return () => {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
        };
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setShowResults(true);

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        if (value.length >= 2) {
            searchTimeout.current = setTimeout(() => {
                performSearch(value);
            }, 300);
        } else {
            setSearchResults([]);
        }
    };

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        setSearchTerm('');
        setSearchResults([]);
    };

    const performSearch = async (term) => {
        if (!term) return;

        try {
            setLoading(true);
            const response = await fetchWithAuth(
                `/api/patient/search?searchTerm=${encodeURIComponent(term)}&searchType=${searchType}`
            );
            setSearchResults(response.$values || []);
        } catch (error) {
            showMessage('error', 'Error searching for patients');
            console.error('Error searching patients:', error);
            setSearchResults([]);
        } finally {
            setLoading(false);
        }
    };

    const handlePatientSelect = (patient) => {
        onSelect(patient);
        setSearchTerm('');
        setSearchResults([]);
        setShowResults(false);
    };

    const renderSearchResults = () => {
        if (!showResults) return null;

        if (loading) {
            return (
                <div className="search-results loading">
                    <Loader className="spinner" />
                    Searching...
                </div>
            );
        }

        if (searchResults.length === 0 && searchTerm.length >= 2) {
            return (
                <div className="search-results empty">
                    No patients found
                </div>
            );
        }

        if (searchResults.length > 0) {
            return (
                <div className="search-results">
                    {searchResults.map(patient => (
                        <div
                            key={patient.PatientId}
                            className="search-result-item"
                            onClick={() => handlePatientSelect(patient)}
                        >
                            <div className="patient-primary">
                                <span className="patient-name">
                                    {patient.FirstName} {patient.LastName}
                                </span>
                                <span className="patient-dob">
                                    {new Date(patient.DateOfBirth).toLocaleDateString()}
                                </span>
                            </div>
                            <div className="patient-secondary">
                                {patient.PhoneNumber && (
                                    <span className="patient-phone">
                                        {formatPhoneNumber(patient.PhoneNumber)}
                                    </span>
                                )}
                                {patient.EmailAddress && (
                                    <span className="patient-email">
                                        {patient.EmailAddress}
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    return (
        <div className="patient-search" ref={searchRef}>
            <div className="search-container">
                <div className="search-type">
                    <select
                        value={searchType}
                        onChange={handleSearchTypeChange}
                        className="search-type-select"
                    >
                        <option value="lastName">Last Name</option>
                        <option value="phone">Phone</option>
                        <option value="dob">Date of Birth</option>
                    </select>
                </div>
                <div className="search-input-wrapper">
                    <Search className="search-icon" size={18} />
                    <input
                        type="text"
                        placeholder={`Search patients by ${searchType.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    {loading && <Loader className="spinner" size={18} />}
                </div>
            </div>
            {renderSearchResults()}
        </div>
    );
};

export default PatientSearch;
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import './Message.css';

const Message = ({ type, message, onConfirm, onCancel }) => {
    return (
        <div className={`message message--${type}`}>
            <p>{message}</p>
            {(type === 'error' || type === 'success') && (
                <div className="message__actions">
                    <Button onClick={onConfirm} className="dark-green" circular>
                        OK
                    </Button>
                </div>
            )}
            {type === 'confirmation' && (
                <div className="message__actions">
                    <Button onClick={onConfirm} primary>
                        Yes
                    </Button>
                    <Button onClick={onCancel} secondary>
                        Cancel
                    </Button>
                </div>
            )}
        </div>
    );
};

Message.propTypes = {
    type: PropTypes.oneOf(['error', 'confirmation', 'success']).isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
};

export default Message;
// Cerebro/ClientApp/src/pages/Dashboard/Studies/Dialog/AddStudy/AddStudy.js

import React, { useState, useEffect } from 'react';
import { useMessage } from '../../../../../components/Message/MessageProvider';
import backIcon from '../../../../../assets/icons/Close.png';
import plusIcon from '../../../../../assets/icons/Plus.png';
import minusIcon from '../../../../../assets/icons/Minus.png';
import AddProtocol from './AddProtocol';
import AddSponsor from './AddSponsor';
import './AddStudy.css';
import useFetchWithAuth from '../../../../../utils/useFetchWithAuth';

const AddStudy = ({ initialProtocolNumbers, initialSponsors, onSave, onClose }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const [protocolNumbers, setProtocolNumbers] = useState(initialProtocolNumbers || []);
    const [sponsors, setSponsors] = useState(initialSponsors || []);
    const [protocolNumber, setProtocolNumber] = useState('');
    const [studyName, setStudyName] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [showAddProtocol, setShowAddProtocol] = useState(false);
    const [showAddSponsor, setShowAddSponsor] = useState(false);
    const { showMessage } = useMessage();

    const fetchProtocolNumbersAndSponsors = async () => {
        try {
            const protocolNumbersData = await fetchWithAuth('/api/Protocol');
            const sponsorsData = await fetchWithAuth('/api/Sponsor');
            setProtocolNumbers(protocolNumbersData.$values || []);
            setSponsors(sponsorsData.$values || []);
        } catch (error) {
            showMessage('error', `Failed to fetch protocols and sponsors: ${error.message}`, 0, 0);
        }
    };

    useEffect(() => {
        fetchProtocolNumbersAndSponsors();
    }, []);

    const handleDeleteProtocolNumber = () => {
        if (!protocolNumber || protocolNumber === 'Select a protocol number') {
            showMessage('error', 'Please select a Protocol Number to delete.', 0, 0);
            return;
        }
        showMessage('confirmation', `Are you sure you want to delete Protocol ${protocolNumber}?`, 0, 0, async () => {
            await deleteProtocolNumber(protocolNumber);
            showMessage('success', 'Protocol deleted successfully.', 0, 0);
            setProtocolNumber('');
            await fetchProtocolNumbersAndSponsors();
        });
    };

    const handleDeleteSponsor = () => {
        if (!sponsor || sponsor === 'Select a sponsor') {
            showMessage('error', 'Please select a Sponsor to delete.', 0, 0);
            return;
        }
        showMessage('confirmation', `Are you sure you want to delete Sponsor ${sponsor}?`, 0, 0, async () => {
            await deleteSponsor(sponsor);
            showMessage('success', 'Sponsor deleted successfully.', 0, 0);
            setSponsor('');
            await fetchProtocolNumbersAndSponsors();
        });
    };

    const deleteProtocolNumber = async (protocolNumber) => {
        try {
            const response = await fetchWithAuth(`/api/Protocol/${protocolNumber}`, { method: 'DELETE' });
            if (!response.ok) {
                showMessage('error', 'Failed to delete Protocol Number.', 0, 0);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while deleting the Protocol Number.', 0, 0);
        }
    };

    const deleteSponsor = async (sponsor) => {
        try {
            const response = await fetchWithAuth(`/api/Sponsor/${sponsor}`, { method: 'DELETE' });
            if (!response.ok) {
                showMessage('error', 'Failed to delete Sponsor.', 0, 0);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while deleting the Sponsor.', 0, 0);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!protocolNumber || !studyName || !sponsor) {
            showMessage('error', 'All fields are required.', 0, 0);
            return;
        }

        try {
            const existingStudies = await fetchWithAuth(`/api/study/byName?studyName=${studyName}`);

            if (existingStudies.$values && existingStudies.$values.length > 0) {
                showMessage('error', 'A study with this name already exists.', 0, 0);
                return;
            }

            const newStudy = {
                protocolNumber,
                studyName,
                sponsor,
            };

            const data = await fetchWithAuth('/api/study', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newStudy),
            });

            setProtocolNumber('');
            setStudyName('');
            setSponsor('');
            onSave({
                protocolNumber,
                studyName,
                sponsor,
                studyId: data.studyId,
            });
            showMessage('success', 'Study added successfully!', 0, 0);

        } catch (error) {
            showMessage('error', 'An error occurred while adding the study. Please try again.', 0, 0);
        }
    };

    const handleBackClick = () => {
        onClose();
    };

    const handleAddProtocol = () => {
        setShowAddProtocol(true);
    };

    const handleAddSponsor = () => {
        setShowAddSponsor(true);
    };

    return (
        <div className="add-study-container">
            <div className={`add-study-form-wrapper ${showAddProtocol || showAddSponsor ? 'inactive' : ''}`}>
                <div className="add-study-form">
                    <img src={backIcon} alt="Back" className="back-icon" onClick={handleBackClick} />
                    <form onSubmit={handleSubmit}>
                        <label className="study-name-label">
                            Study Name
                            <input type="text" className="study-name-input" value={studyName} onChange={(e) => setStudyName(e.target.value)} required />
                        </label>
                        <label className="protocol-label">
                            Protocol Number
                            <div className="input-with-icons">
                                <select className="protocol-input" value={protocolNumber} onChange={(e) => setProtocolNumber(e.target.value)} required>
                                    <option value="" disabled>Select a protocol number</option>
                                    {protocolNumbers.map(protocol => (
                                        <option key={protocol.ProtocolNumber} value={protocol.ProtocolNumber}>
                                            {protocol.ProtocolNumber}
                                        </option>
                                    ))}
                                </select>
                                <img src={plusIcon} alt="Plus" className="icon plus-icon" onClick={handleAddProtocol} />
                                <img src={minusIcon} alt="Minus" className="icon minus-icon" onClick={handleDeleteProtocolNumber} />
                            </div>
                        </label>
                        <label className="sponsor-label">
                            Sponsor
                            <div className="input-with-icons">
                                <select className="sponsor-input" value={sponsor} onChange={(e) => setSponsor(e.target.value)} required>
                                    <option value="" disabled>Select a sponsor</option>
                                    {sponsors.map(sponsor => (
                                        <option key={sponsor.SponsorName} value={sponsor.SponsorName}>
                                            {sponsor.SponsorName}
                                        </option>
                                    ))}
                                </select>
                                <img src={plusIcon} alt="Plus" className="icon plus-icon" onClick={handleAddSponsor} />
                                <img src={minusIcon} alt="Minus" className="icon minus-icon" onClick={handleDeleteSponsor} />
                            </div>
                        </label>
                        <div className="form-buttons">
                            <button type="submit">Save Study</button>
                        </div>
                    </form>
                </div>
            </div>
            {showAddProtocol && <AddProtocol onClose={() => setShowAddProtocol(false)} onSave={fetchProtocolNumbersAndSponsors} />}
            {showAddSponsor && <AddSponsor onClose={() => setShowAddSponsor(false)} onSave={fetchProtocolNumbersAndSponsors} />}
        </div>
    );
};

export default AddStudy;
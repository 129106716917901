// src/components/api-authorization/AuthorizeRoute.js

import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

const AuthorizeRoute = ({ element }) => {
    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();

        return () => {
            authService.unsubscribe(subscription);
        };
    }, []);

    const populateAuthenticationState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        setReady(true);
        setAuthenticated(isAuthenticated);
    };


    const authenticationChanged = async () => {
        setReady(false);
        setAuthenticated(false);
        await populateAuthenticationState();
    };

    if (!ready) {
        return <div>Loading...</div>; // Optional: Add a loading spinner or message
    } else {
        const returnUrl = `${location.pathname}${location.search}${location.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
        return authenticated ? element : <Navigate replace to={redirectUrl} />;
    }
};

export default AuthorizeRoute;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMessage } from '../../components/Message/MessageProvider';
import './ResetPassword.css';

const ResetPassword = () => {
    const { userId, token, email } = useParams();
    const navigate = useNavigate();
    const { showMessage } = useMessage();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const decodedToken = decodeURIComponent(token);
    const decodedEmail = decodeURIComponent(email);

    useEffect(() => {
    }, [userId, decodedToken, decodedEmail]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            showMessage('error', 'Passwords do not match.', -100, 0);
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/resetpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                    token: decodedToken,
                    email: decodedEmail,
                    newPassword,
                    confirmPassword
                }),
            });

            const data = await response.json();

            if (response.ok) {
                showMessage('success', 'Password reset successful. You can now log in with your new password.', 0, 0);
                navigate('/login');
            } else {
                if (data.message === "Password reset link has expired. Please request a new one.") {
                    showMessage('error', data.message, -100, 0);
                    navigate('/login');
                } else if (data.errors && typeof data.errors === 'object') {
                    const errorMessages = Object.values(data.errors).flat();
                    showMessage('error', errorMessages.join(' '), -100, 0);
                } else {
                    showMessage('error', data.message || 'Password reset failed.', -100, 0);
                }
            }
        } catch (error) {
            showMessage('error', 'An unexpected error occurred. Please try again later.', -100, 0);
        }
    };

    return (
        <div className="form-block">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    required
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm New Password"
                    required
                />
                <button type="submit">Reset Password</button>
            </form>
        </div>
    );
};

export default ResetPassword;

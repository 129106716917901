// Cerebro/ClientApp/src/pages/Dashboard/Studies/Studies.js

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import './Studies.css';
import AddStudy from './Dialog/AddStudy/AddStudy';
import useFetchWithAuth from '../../../utils/useFetchWithAuth';
import { useMessage } from '../../../components/Message/MessageProvider';
import backIcon from '../../../assets/icons/Close.png';
import editIcon from '../../../assets/icons/Edit.png';
import deleteIcon from '../../../assets/icons/Delete.png';
import checkIcon from '../../../assets/icons/Check.png';

const Studies = () => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [protocols, setProtocols] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [selectedProtocol, setSelectedProtocol] = useState('');
    const [selectedSponsor, setSelectedSponsor] = useState('');
    const [selectedStudy, setSelectedStudy] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [showAddStudyModal, setShowAddStudyModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedStudy, setEditedStudy] = useState(null);

    useEffect(() => {
        fetchData();
        fetchProtocolsAndSponsors();
    }, []);

    const fetchData = async () => {
        try {
            const result = await fetchWithAuth('/api/study');
            const studiesArray = result && result.$values ? result.$values : [];
            setData(studiesArray);
        } catch (error) {
            showMessage('error', 'Error fetching data from server.', 0, 0);
            setData([]);
        }
    };

    const fetchProtocolsAndSponsors = async () => {
        try {
            const protocolsResult = await fetchWithAuth('/api/protocol');
            const sponsorsResult = await fetchWithAuth('/api/sponsor');

            const protocolsArray = protocolsResult && protocolsResult.$values ? protocolsResult.$values : [];
            const sponsorsArray = sponsorsResult && sponsorsResult.$values ? sponsorsResult.$values : [];

            setProtocols(protocolsArray.map(p => p.ProtocolNumber));
            setSponsors(sponsorsArray.map(s => s.SponsorName));
        } catch (error) {
            showMessage('error', `Failed to fetch protocols and sponsors: ${error.message}`, 0, 0);
        }
    };

    const filteredData = useMemo(() => {
        return data.filter(item =>
            item.StudyName.toLowerCase().includes(filterText.toLowerCase()) &&
            (selectedProtocol === '' || item.ProtocolNumber === selectedProtocol) &&
            (selectedSponsor === '' || item.Sponsor === selectedSponsor)
        );
    }, [filterText, data, selectedProtocol, selectedSponsor]);

    const onFilterChange = useCallback((newFilter) => {
        setFilterText(newFilter);
    }, []);

    const onProtocolChange = useCallback((newProtocol) => {
        setSelectedProtocol(newProtocol);
    }, []);

    const onSponsorChange = useCallback((newSponsor) => {
        setSelectedSponsor(newSponsor);
    }, []);

    const handleRowClick = useCallback((study) => {
        setSelectedStudy(study);
        setEditedStudy(study);
        setShowDetail(true);
    }, []);

    const handleCloseDetail = useCallback(() => {
        setShowDetail(false);
        setIsEditing(false);
        setEditedStudy(null);
    }, []);

    const handleOpenAddStudy = () => {
        fetchProtocolsAndSponsors();
        setShowAddStudyModal(true);
    };

    const handleCloseAddStudy = () => {
        setShowAddStudyModal(false);
    };

    const handleAddStudySuccess = (newStudy) => {
        fetchData();
        showMessage('success', 'Study added successfully!', 3000);
        setShowAddStudyModal(false);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async (editedStudy) => {
        try {
            const result = await fetchWithAuth('/api/study', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedStudy),
            });
            if (result) {
                setSelectedStudy(result);
                setEditedStudy(result);
                setIsEditing(false);
                fetchData();
                showMessage('success', 'Study updated successfully!', 0, 0);
            }
        } catch (error) {
            showMessage('error', 'Error updating study.', 0, 0);
        }
    };

    const handleDelete = (study) => {
        showMessage('confirmation', `Are you sure you want to delete the study "${study.StudyName}"?`, 0, 0,
            async () => {
                try {
                    await fetchWithAuth(`/api/study/${study.StudyId}`, {
                        method: 'DELETE',
                    });
                    fetchData();
                    setShowDetail(false);
                    showMessage('success', 'Study deleted successfully!', 0, 0);
                } catch (error) {
                    showMessage('error', 'Error deleting study.', 0, 0);
                }
            }
        );
    };

    const FilterInput = ({ onFilterChange }) => {
        return (
            <input
                type="text"
                className="filter-input"
                onChange={e => onFilterChange(e.target.value)}
                placeholder="Filter by Study Name"
            />
        );
    };

    const Dropdown = ({ options, onChange, placeholder }) => {
        return (
            <select className="header-dropdown" onChange={e => onChange(e.target.value)}>
                <option value="">{placeholder}</option>
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        );
    };

    const StudyDetail = ({ study, onClose, onEdit, isEditing, onSave, onDelete, protocols, sponsors, editedStudy, setEditedStudy }) => {
        if (!study) return null;

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setEditedStudy(prev => ({ ...prev, [name]: value }));
        };

        return (
            <div className={`study-detail ${isEditing ? 'editing' : ''}`}>
                <div className="study-actions-left">
                    <img src={deleteIcon} alt="Delete" onClick={() => onDelete(study)} />
                    <img
                        src={isEditing ? checkIcon : editIcon}
                        alt={isEditing ? "Save" : "Edit"}
                        onClick={isEditing ? () => onSave(editedStudy) : onEdit}
                    />
                </div>
                <div className="study-info">
                    <div>
                        <strong>Study Name:</strong>
                        {isEditing ? (
                            <input
                                type="text"
                                name="StudyName"
                                value={editedStudy.StudyName}
                                onChange={handleInputChange}
                            />
                        ) : <span>{study.StudyName}</span>}
                    </div>
                    <div>
                        <strong>Protocol Number:</strong>
                        {isEditing ? (
                            <select
                                name="ProtocolNumber"
                                value={editedStudy.ProtocolNumber}
                                onChange={handleInputChange}
                            >
                                {protocols.map(protocol => (
                                    <option key={protocol} value={protocol}>{protocol}</option>
                                ))}
                            </select>
                        ) : <span>{study.ProtocolNumber}</span>}
                    </div>
                    <div>
                        <strong>Sponsor:</strong>
                        {isEditing ? (
                            <select
                                name="Sponsor"
                                value={editedStudy.Sponsor}
                                onChange={handleInputChange}
                            >
                                {sponsors.map(sponsor => (
                                    <option key={sponsor} value={sponsor}>{sponsor}</option>
                                ))}
                            </select>
                        ) : <span>{study.Sponsor}</span>}
                    </div>
                </div>
                <div className="study-actions-right">
                    <img src={backIcon} alt="Back" onClick={onClose} />
                </div>
            </div>
        );
    };

    const columns = useMemo(() => [
        {
            header: () => (
                <div className="header-flex-container">
                    <span>Study Name</span>
                    <FilterInput onFilterChange={onFilterChange} />
                </div>
            ),
            cell: (info) => info.getValue(),
            accessorKey: 'StudyName',
        },
        {
            header: () => (
                <div className="header-flex-container">
                    <span>Protocol Number</span>
                    <Dropdown options={protocols} onChange={onProtocolChange} placeholder="Filter by Protocol" />
                </div>
            ),
            cell: (info) => info.getValue(),
            accessorKey: 'ProtocolNumber',
        },
        {
            header: () => (
                <div className="header-flex-container">
                    <span>Sponsor</span>
                    <Dropdown options={sponsors} onChange={onSponsorChange} placeholder="Filter by Sponsor" />
                </div>
            ),
            cell: (info) => info.getValue(),
            accessorKey: 'Sponsor',
        }
    ], [onFilterChange, onProtocolChange, onSponsorChange, protocols, sponsors]);

    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="investigational-product-wrapper">
            <h1 className="page-title">Studies</h1>
            <div className="top-row">
                <button className="add-study-button" onClick={handleOpenAddStudy}>
                    Add a Study
                </button>
            </div>
            <div className="data-table-container" style={{ position: 'relative' }}>
                <div className="data-table-wrapper">
                    <table className="data-table">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} onClick={() => handleRowClick(row.original)}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {showDetail && (
                    <StudyDetail
                        study={selectedStudy}
                        onClose={handleCloseDetail}
                        onEdit={handleEdit}
                        isEditing={isEditing}
                        onSave={handleSave}
                        onDelete={handleDelete}
                        protocols={protocols}
                        sponsors={sponsors}
                        editedStudy={editedStudy}
                        setEditedStudy={setEditedStudy}
                    />
                )}
            </div>
            {showAddStudyModal && (
                <div className="modal-overlay">
                    <AddStudy
                        protocols={protocols}
                        sponsors={sponsors}
                        onSave={handleAddStudySuccess}
                        onClose={handleCloseAddStudy}
                    />
                </div>
            )}
        </div>
    );
};

export default Studies;
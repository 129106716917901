// Cerebro/ClientApp/src/pages/Dashboard/Dashboard.js

import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Dashboard.css';
import backgroundImage from '../../assets/goat.jpg';
import tempIcon from '../../assets/icons/TempDashboardIcon.png';
import ehrIcon from '../../assets/icons/EHR.png';
import ipIcon from '../../assets/icons/IP.png';
import subjectsIcon from '../../assets/icons/Subjects.png';
import schedulerIcon from '../../assets/icons/Schedule.png';
import inventoryIcon from '../../assets/icons/Inventory.png';
import studiesIcon from '../../assets/icons/Studies.png';
import extractionIcon from '../../assets/icons/PatientDataEx.png';
import adminIcon from '../../assets/icons/Admin.png';
import backIcon from '../../assets/icons/Back.png';
import userIcon from '../../assets/icons/User.png';
import logoIcon from '../../assets/cerebro_white_logo.png';
import authService from '../../components/api-authorization/AuthorizeService';
import { useMessage } from '../../components/Message/MessageProvider';
import { useIdleTimer } from 'react-idle-timer';
import IP from './IP/IP';
import Subjects from './Subjects/Subjects';
import Scheduler from './Scheduler/Scheduler';
import Inventory from './Inventory/Inventory';
import Studies from './Studies/Studies';
import EHR from './EHR/EHR';
import PatientDataExtraction from './PatientDataExtraction/PatientDataExtraction';
import Admin from './Admin/Admin';

const Dashboard = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [userRoles, setUserRoles] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { showMessage } = useMessage();
    const [hasOnlyOneApp, setHasOnlyOneApp] = useState(false);
    const idleTimer = 30; // Set minutes till idle timeout
    const [initialLoginTimestamp, setInitialLoginTimestamp] = useState(0);

    const checkAuthentication = useCallback(async () => {
        const isAuthenticated = await authService.isAuthenticated();
        const currentLastLogin = authService.getLastLoginTimestamp();

        if (!isAuthenticated) {
            navigate('/', { replace: true });
        } else if (initialLoginTimestamp === 0) {
            // Initial login, set the timestamp
            setInitialLoginTimestamp(currentLastLogin);
        } else if (currentLastLogin !== initialLoginTimestamp) {
            // A new login has occurred, redirect to login page
            navigate('/', { replace: true });
        }
    }, [navigate, initialLoginTimestamp]);

    useEffect(() => {
        checkAuthentication();
    }, [checkAuthentication]);

    useEffect(() => {
        const handlePopState = () => {
            checkAuthentication();
        };

        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [checkAuthentication]);

    const performLogout = useCallback(async () => {
        await authService.signOut();
        navigate('/');
    }, [navigate]);

    const handleIdle = useCallback(() => {
        performLogout();
    }, [performLogout]);

    const { reset } = useIdleTimer({
        timeout: 1000 * 60 * idleTimer,
        onIdle: handleIdle,
        debounce: 500
    });

    useEffect(() => {
        if (location.state && location.state.userRoles) {
            setUserRoles(location.state.userRoles);
            handleInitialNavigation(location.state.userRoles);
        } else {
            fetchUserRoles();
        }
    }, [location]);

    const fetchUserRoles = async () => {
        try {
            const roles = await authService.fetchUserRoles();
            setUserRoles(roles);
            handleInitialNavigation(roles);
        } catch (error) {
            showMessage('error', 'Error fetching user roles.', 0, 0);
        }
    };

    const handleInitialNavigation = (roles) => {
        const nonAdminRoles = ['InvestigationalProduct', 'Subjects', 'Scheduler', 'Inventory', 'Studies'];
        const userNonAdminRoles = nonAdminRoles.filter(role => roles[role]);

        if (userNonAdminRoles.length === 1 && !roles.IsAdmin) {
            const singleAppComponent = icons.find(icon => icon.roleName === userNonAdminRoles[0]).component;
            setCurrentPage(singleAppComponent);
            setHasOnlyOneApp(true);
        } else {
            setHasOnlyOneApp(false);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = useCallback(async () => {
        await authService.signOut();
        navigate('/', { replace: true });
    }, [navigate]);

    const handleIconClick = (page) => {
        setCurrentPage(page);
    };

    const handleBackClick = () => {
        if (!hasOnlyOneApp) {
            setCurrentPage(null);
        }
    };

    const icons = [
        { id: 1, label: 'IP', icon: ipIcon, component: <IP />, roleName: 'InvestigationalProduct' },
        { id: 2, label: 'Subjects', icon: subjectsIcon, component: <Subjects />, roleName: 'Subjects' },
        { id: 3, label: 'Scheduler', icon: schedulerIcon, component: <Scheduler />, roleName: 'Scheduler' },
        { id: 4, label: 'Inventory', icon: inventoryIcon, component: <Inventory />, roleName: 'Inventory' },
        { id: 5, label: 'Studies', icon: studiesIcon, component: <Studies />, roleName: 'Studies' },
        { id: 6, label: 'EHR', icon: ehrIcon, component: <EHR />, roleName: 'EHR' },
        { id: 7, label: 'Patient Data Extraction', icon: extractionIcon, component: <PatientDataExtraction />, roleName: 'PatientDataExtraction' },
        { id: 8, label: 'Admin', icon: adminIcon, component: <Admin />, roleName: 'IsAdmin' },
    ];

    const renderIcons = () => {
        if (!userRoles) return null;

        const availableIcons = icons.filter(icon => userRoles[icon.roleName]);

        if (availableIcons.length === 0) {
            return (
                <div className="no-access-message">
                    <p>You currently have no app access.</p>
                    <p>Please contact an admin for access.</p>
                </div>
            );
        }

        return (
            <div className="icons-container">
                <div className="icons-box">
                    <div className="icons-grid" role="menu">
                        {availableIcons.map((item) => (
                            <div
                                key={item.id}
                                className="icon-box"
                                onClick={() => handleIconClick(item.component)}
                                role="menuitem"
                                tabIndex="0"
                                aria-label={`Open ${item.label} application`}
                            >
                                <img src={item.icon} alt="" className="dashboard-icon" />
                                <p>{item.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="dashboard-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="dashboard-content">
                <header className="header">
                    <div className="header-left">
                        <button
                            className={`back-button ${!currentPage || hasOnlyOneApp ? 'inactive' : ''}`}
                            onClick={currentPage && !hasOnlyOneApp ? handleBackClick : null}
                            aria-label="Back to Dashboard"
                            disabled={!currentPage || hasOnlyOneApp}
                        >
                            <img src={backIcon} alt="" />
                        </button>
                    </div>
                    <div className="header-center">
                        <img src={logoIcon} alt="Cerebro Logo" className="cerebro-logo" />
                    </div>
                    <div className="header-right">
                        <div className="user-menu">
                            <button
                                onClick={toggleDropdown}
                                className="profile-button"
                                aria-haspopup="true"
                                aria-expanded={dropdownOpen}
                            >
                                <img src={userIcon} alt="User menu" />
                            </button>
                            {dropdownOpen && (
                                <div className="profile-menu" role="menu">
                                    <button onClick={handleLogout} role="menuitem">Logout</button>
                                </div>
                            )}
                        </div>
                    </div>
                </header>
                <div className="header-separator"></div>
                <main className="content-area">
                    {currentPage ? (
                        <div className="page-content">{currentPage}</div>
                    ) : (
                        renderIcons()
                    )}
                </main>
            </div>
        </div>
    );
};

export default Dashboard;
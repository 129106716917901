import React, { createContext, useContext, useState } from 'react';
import Message from './Message';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    const showMessage = (type, message, topOffset = 0, leftOffset = 0, onConfirm, onCancel) => {
        setMessage({ type, message, topOffset, leftOffset, onConfirm, onCancel });
    };

    const hideMessage = () => {
        setMessage(null);
    };

    return (
        <MessageContext.Provider value={{ showMessage, hideMessage }}>
            {children}
            {message && (
                <div className="message-overlay">
                    <Message
                        type={message.type}
                        message={message.message}
                        topOffset={message.topOffset}
                        leftOffset={message.leftOffset}
                        onConfirm={() => {
                            if (message.onConfirm) {
                                message.onConfirm();
                            }
                            hideMessage();
                        }}
                        onCancel={() => {
                            if (message.onCancel) {
                                message.onCancel();
                            }
                            hideMessage();
                        }}
                    />
                </div>
            )}
        </MessageContext.Provider>
    );
};

export default MessageProvider;
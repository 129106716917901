// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/AddVisitDialog.js

import React, { useState, useEffect } from 'react';
import { X, Search, Plus } from 'lucide-react';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import PatientSearch from './PatientSearch';
import AddPatient from '../../EHR/Dialog/AddPatient';
import './AddVisitDialog.css';

const AddVisitDialog = ({ onClose, onSave, selectedDate }) => {
    const { fetchWithAuth, postWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [templates, setTemplates] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [showAddPatient, setShowAddPatient] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        patientId: '',
        scheduledDateTime: selectedDate || new Date(),
        durationMinutes: 30,
        status: 'Scheduled',
        notes: '',
        visitTemplateId: '',
    });

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        try {
            const response = await fetchWithAuth('/api/visittemplate/company/1'); // Replace with actual company ID
            setTemplates(response.$values || []);
        } catch (error) {
            showMessage('error', 'Error fetching visit templates');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handlePatientSelect = (patient) => {
        setSelectedPatient(patient);
        setFormData(prev => ({
            ...prev,
            patientId: patient.PatientId
        }));
    };

    const handleShowAddPatient = () => {
        setShowAddPatient(true);
    };

    const handlePatientAdded = (newPatient) => {
        setShowAddPatient(false);
        setSelectedPatient(newPatient);
        setFormData(prev => ({
            ...prev,
            patientId: newPatient.PatientId
        }));
    };

    const handleDateTimeChange = (e) => {
        const { name, value } = e.target;
        // Combine date and time if necessary
        if (name === 'date' || name === 'time') {
            const currentDateTime = new Date(formData.scheduledDateTime);
            const [year, month, day] = name === 'date' ? value.split('-') : [
                currentDateTime.getFullYear(),
                currentDateTime.getMonth() + 1,
                currentDateTime.getDate()
            ];
            const [hours, minutes] = name === 'time' ? value.split(':') : [
                currentDateTime.getHours(),
                currentDateTime.getMinutes()
            ];

            const newDateTime = new Date(year, month - 1, day, hours, minutes);
            setFormData(prev => ({
                ...prev,
                scheduledDateTime: newDateTime
            }));
        } else {
            handleInputChange(e);
        }
    };

    const validateForm = () => {
        if (!selectedPatient) {
            showMessage('error', 'Please select a patient');
            return false;
        }
        if (!formData.scheduledDateTime) {
            showMessage('error', 'Please select a date and time');
            return false;
        }
        if (!formData.durationMinutes || formData.durationMinutes < 15) {
            showMessage('error', 'Duration must be at least 15 minutes');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setLoading(true);
            const response = await postWithAuth('/api/visit', formData);
            if (response && response.visitId) {
                showMessage('success', 'Visit scheduled successfully');
                onSave(response);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            showMessage('error', 'Error scheduling visit');
            console.error('Error scheduling visit:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="dialog-overlay">
            <div className="add-visit-dialog">
                <div className="dialog-header">
                    <h2>Schedule New Visit</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="dialog-content">
                    <div className="form-section">
                        <h3>Patient Information</h3>
                        {!selectedPatient ? (
                            <div className="patient-search-container">
                                <PatientSearch onSelect={handlePatientSelect} />
                                <button
                                    type="button"
                                    className="add-patient-button"
                                    onClick={handleShowAddPatient}
                                >
                                    <Plus size={20} />
                                    Add New Patient
                                </button>
                            </div>
                        ) : (
                            <div className="selected-patient">
                                <div className="patient-info">
                                    <span className="patient-name">
                                        {selectedPatient.FirstName} {selectedPatient.LastName}
                                    </span>
                                    <button
                                        type="button"
                                        className="change-patient"
                                        onClick={() => setSelectedPatient(null)}
                                    >
                                        Change
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-section">
                        <h3>Visit Details</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    value={formData.scheduledDateTime.toISOString().split('T')[0]}
                                    onChange={handleDateTimeChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="time">Time</label>
                                <input
                                    type="time"
                                    id="time"
                                    name="time"
                                    value={formData.scheduledDateTime.toTimeString().slice(0, 5)}
                                    onChange={handleDateTimeChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="durationMinutes">Duration (minutes)</label>
                                <select
                                    id="durationMinutes"
                                    name="durationMinutes"
                                    value={formData.durationMinutes}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="visitTemplateId">Visit Template</label>
                            <select
                                id="visitTemplateId"
                                name="visitTemplateId"
                                value={formData.visitTemplateId}
                                onChange={handleInputChange}
                            >
                                <option value="">Select a template...</option>
                                {templates.map(template => (
                                    <option key={template.visitTemplateId} value={template.visitTemplateId}>
                                        {template.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="notes">Notes</label>
                            <textarea
                                id="notes"
                                name="notes"
                                value={formData.notes}
                                onChange={handleInputChange}
                                rows={4}
                            />
                        </div>
                    </div>

                    <div className="dialog-actions">
                        <button
                            type="button"
                            className="cancel-button"
                            onClick={onClose}
                            disabled={loading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="save-button"
                            disabled={loading}
                        >
                            {loading ? 'Scheduling...' : 'Schedule Visit'}
                        </button>
                    </div>
                </form>
            </div>

            {showAddPatient && (
                <div className="modal-overlay">
                    <AddPatient
                        onClose={() => setShowAddPatient(false)}
                        onSave={handlePatientAdded}
                    />
                </div>
            )}
        </div>
    );
};

export default AddVisitDialog;
// Cerebro/ClientApp/src/utils/useFetchWithAuth.js

const getToken = () => {
    return localStorage.getItem('auth_token');
};

const baseRequest = async (endpoint, options = {}) => {
    const baseURL = process.env.REACT_APP_API_URL || '';
    if (!baseURL) {
        throw new Error('Base URL is not set.');
    }
    const url = `${baseURL}${endpoint}`;
    const token = getToken();
    if (!token) {
        throw new Error('User is not authenticated');
    }
    const headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
    };
    try {
        const response = await fetch(url, {
            ...options,
            headers,
        });
        if (response.status === 304) {
            return null;
        }
        if (!response.ok) {
            const contentType = response.headers.get("content-type");
            let errorData;
            if (contentType && contentType.indexOf("application/json") !== -1) {
                errorData = await response.json();
            } else {
                errorData = { message: await response.text() };
            }
            if (errorData.errors) {
                // Handle errors if needed
            }
            throw new Error(errorData.message || 'Failed to fetch');
        }
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return await response.json();
        } else {
            return await response.text();
        }
    } catch (error) {
        throw error;
    }
};

export const fetchWithAuth = async (endpoint, options = {}) => {
    return baseRequest(endpoint, options);
};

export const putWithAuth = async (endpoint, data, options = {}) => {
    try {
        const response = await baseRequest(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
            body: JSON.stringify(data),
            ...options,
        });
        return response;
    } catch (error) {
        if (error.response) {
            const contentType = error.response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const errorData = await error.response.json();
                if (errorData.errors) {
                    // Handle errors if needed
                }
            } else {
                const errorText = await error.response.text();
                // Handle error text if needed
            }
        }
        throw error;
    }
};

// Keep the hook for use in React components if needed
export const useFetchWithAuth = () => {
    return { fetchWithAuth, putWithAuth };
};

export default useFetchWithAuth;
// Cerebro/ClientApp/src/pages/Dashboard/EHR/Dialog/AddPatient.js

import React, { useState, useEffect } from 'react';
import './AddPatient.css';
import backIcon from '../../../../assets/icons/Close.png';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import InputMask from 'react-input-mask';
import {
    formatPhoneNumber,
    stripPhoneFormatting,
    formatDateForDisplay,
    formatDateForApi
} from '../../../../utils/formatters';

const AddPatient = ({ onSave, onClose }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [activeTab, setActiveTab] = useState('demographics');
    const [errors, setErrors] = useState({});
    const [patientData, setPatientData] = useState({
        FirstName: '',
        MiddleName: '',
        LastName: '',
        PreferredName: '',
        DateOfBirth: null,
        SexAssignedAtBirthId: '',
        GenderIdentityId: '',
        PreferredPronounId: '',
        PhoneNumber: '',
        EmailAddress: '',
        Street: '',
        City: '',
        StateId: '',
        ZipCode: '',
        CountryId: '',
        EmergencyContactName: '',
        EmergencyContactRelationship: '',
        EmergencyContactPhone: '',
        LegalAuthRepName: '',
        LegalAuthRepRelationship: '',
        LegalAuthRepPhone: '',
        Insurance: {
            Carrier: '',
            Subscriber: '',
            Coverage: '',
            SubscriberId: '',
            Group: '',
            RxBIN: ''
        }
    });

    const [dropdownOptions, setDropdownOptions] = useState({
        sexAssignedAtBirth: [],
        genderIdentity: [],
        preferredPronouns: [],
        states: [],
        countries: [],
    });

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const [
                    sexAssignedAtBirthData,
                    genderIdentityData,
                    preferredPronounsData,
                    statesData,
                    countriesData,
                ] = await Promise.all([
                    fetchWithAuth('/api/patient/sexAssignedAtBirthOptions'),
                    fetchWithAuth('/api/patient/genderIdentityOptions'),
                    fetchWithAuth('/api/patient/preferredPronounOptions'),
                    fetchWithAuth('/api/patient/stateOptions'),
                    fetchWithAuth('/api/patient/countryOptions'),
                ]);

                // Helper function to parse data
                const parseData = (data) => {
                    if (typeof data === 'object' && data !== null && '$values' in data) {
                        return data.$values;
                    }
                    if (Array.isArray(data)) {
                        return data;
                    }
                    console.error('Unexpected data format:', data);
                    return [];
                };

                setDropdownOptions({
                    sexAssignedAtBirth: parseData(sexAssignedAtBirthData),
                    genderIdentity: parseData(genderIdentityData),
                    preferredPronouns: parseData(preferredPronounsData),
                    states: parseData(statesData),
                    countries: parseData(countriesData),
                });
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
                setDropdownOptions({
                    sexAssignedAtBirth: [],
                    genderIdentity: [],
                    preferredPronouns: [],
                    states: [],
                    countries: [],
                });
            }
        };

        fetchDropdownOptions();
    }, [fetchWithAuth]);

    const isPartialDOB = (dob) => {
        if (!dob) return false;
        const defaultPlaceholder = 'mm/dd/yyyy';
        if (dob === defaultPlaceholder) return false;

        const regexComplete = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (regexComplete.test(dob)) return false;

        return true;
    };

    const isValidDate = (dateString) => {
        if (!dateString) return true; // Null/empty is valid

        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (!regex.test(dateString)) return false;

        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'DateOfBirth') {
            newValue = value.trim() === '' ? null : value;
        }

        if (name.startsWith('Insurance.')) {
            const insuranceField = name.split('.')[1];
            setPatientData(prevData => ({
                ...prevData,
                Insurance: {
                    ...prevData.Insurance,
                    [insuranceField]: newValue
                }
            }));
        } else {
            setPatientData(prevData => ({
                ...prevData,
                [name]: newValue
            }));
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const [month, day, year] = dob.split('/');
        const birthDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const validateForm = () => {
        const newErrors = {};

        if (!patientData.FirstName?.trim()) newErrors.FirstName = 'First Name is required.';
        if (!patientData.LastName?.trim()) newErrors.LastName = 'Last Name is required.';

        const phoneRegex = /^\d{10}$/;
        if (patientData.PhoneNumber && !phoneRegex.test(patientData.PhoneNumber)) {
            newErrors.PhoneNumber = 'Please enter a valid 10-digit phone number.';
        }

        if (patientData.EmailAddress && !isValidEmail(patientData.EmailAddress)) {
            newErrors.EmailAddress = 'Please enter a valid email address.';
        }

        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
            newErrors.DateOfBirth = 'Date of Birth must be a valid date.';
        }

        return newErrors;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const patientDataToSend = { ...patientData };
                patientDataToSend.DateOfBirth = formatDateForApi(patientDataToSend.DateOfBirth);

                // Convert IDs to numbers
                patientDataToSend.SexAssignedAtBirthId = Number(patientDataToSend.SexAssignedAtBirthId) || null;
                patientDataToSend.GenderIdentityId = Number(patientDataToSend.GenderIdentityId) || null;
                patientDataToSend.PreferredPronounId = Number(patientDataToSend.PreferredPronounId) || null;
                patientDataToSend.StateId = Number(patientDataToSend.StateId) || null;
                patientDataToSend.CountryId = Number(patientDataToSend.CountryId) || null;

                console.log('Data being sent to backend:', JSON.stringify(patientDataToSend, null, 2));

                const response = await fetchWithAuth('/api/patient', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(patientDataToSend),
                });

                if (response && response.PatientId) {
                    if (onSave && typeof onSave === 'function') {
                        onSave(response);
                    }
                    showMessage('success', 'Patient added successfully!', 3000);
                    onClose();
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                showMessage('error', 'Error adding patient. Please check your input and try again.', 0);
                console.error('Error adding patient:', error);
            }
        } else {
            const errorMessages = Object.values(validationErrors).join(' ');
            showMessage('error', errorMessages || 'Please correct the errors in the form.', 0);
        }
    };

    const handleTabSwitch = (tab) => {
        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                DateOfBirth: 'Date of Birth must be a complete and valid date in MM/DD/YYYY format.'
            }));
            showMessage('error', 'Date of Birth must be a complete and valid date in MM/DD/YYYY format.', 0);
            return;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            DateOfBirth: ''
        }));

        setActiveTab(tab);
    };

    const renderDemographics = () => (
        <div className="demographics-info">
            <div className="form-row">
                <label>
                    <span className="required-field">First Name:</span>
                    <input
                        type="text"
                        name="FirstName"
                        value={patientData.FirstName}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.FirstName ? 'error' : ''}
                    />
                    {errors.FirstName && <span className="error-message">{errors.FirstName}</span>}
                </label>
                <label>
                    Middle Name:
                    <input
                        type="text"
                        name="MiddleName"
                        value={patientData.MiddleName}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    <span className="required-field">Last Name:</span>
                    <input
                        type="text"
                        name="LastName"
                        value={patientData.LastName}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.LastName ? 'error' : ''}
                    />
                    {errors.LastName && <span className="error-message">{errors.LastName}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Preferred Name:
                    <input
                        type="text"
                        name="PreferredName"
                        value={patientData.PreferredName}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Date of Birth:
                    <InputMask
                        mask="99/99/9999"
                        placeholder="mm/dd/yyyy"
                        name="DateOfBirth"
                        value={patientData.DateOfBirth || ''}
                        onChange={handleInputChange}
                        className={errors.DateOfBirth ? 'error' : ''}
                    >
                        {(inputProps) => <input type="text" {...inputProps} />}
                    </InputMask>
                    {errors.DateOfBirth && <span className="error-message">{errors.DateOfBirth}</span>}
                </label>
                <label>
                    Age:
                    <input
                        type="text"
                        value={calculateAge(patientData.DateOfBirth)}
                        readOnly
                        disabled
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Sex Assigned at Birth:
                    <select
                        name="SexAssignedAtBirthId"
                        value={patientData.SexAssignedAtBirthId}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.sexAssignedAtBirth) &&
                            dropdownOptions.sexAssignedAtBirth.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Sex}
                                </option>
                            ))}
                    </select>
                </label>
                <label>
                    Gender Identity:
                    <select
                        name="GenderIdentityId"
                        value={patientData.GenderIdentityId}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.genderIdentity) &&
                            dropdownOptions.genderIdentity.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Gender}
                                </option>
                            ))}
                    </select>
                </label>
                <label>
                    Preferred Pronouns:
                    <select
                        name="PreferredPronounId"
                        value={patientData.PreferredPronounId}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.preferredPronouns) &&
                            dropdownOptions.preferredPronouns.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Pronoun}
                                </option>
                            ))}
                    </select>
                </label>
            </div>
        </div>
    );

    const renderContactInfo = () => (
        <div className="contact-info">
            <div className="form-row">
                <label>
                    Phone Number:
                    <InputMask
                        mask="(999) 999-9999"
                        value={patientData.PhoneNumber || ''}
                        onChange={(e) => {
                            const formattedPhone = stripPhoneFormatting(e.target.value);
                            handleInputChange({
                                target: {
                                    name: 'PhoneNumber',
                                    value: formattedPhone
                                }
                            });
                        }}
                        className={errors.PhoneNumber ? 'error' : ''}
                    />
                    {errors.PhoneNumber && <span className="error-message">{errors.PhoneNumber}</span>}
                </label>
                <label>
                    Email Address:
                    <input
                        type="email"
                        name="EmailAddress"
                        value={patientData.EmailAddress}
                        onChange={handleInputChange}
                        maxLength={100}
                        className={errors.EmailAddress ? 'error' : ''}
                    />
                    {errors.EmailAddress && <span className="error-message">{errors.EmailAddress}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Street:
                    <input
                        type="text"
                        name="Street"
                        value={patientData.Street}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    City:
                    <input
                        type="text"
                        name="City"
                        value={patientData.City}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    State:
                    <select
                        name="StateId"
                        value={patientData.StateId}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.states) &&
                            dropdownOptions.states.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Name}
                                </option>
                            ))}
                    </select>
                </label>
                <label>
                    Zip Code:
                    <input
                        type="text"
                        name="ZipCode"
                        value={patientData.ZipCode}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
                <label>
                    Country:
                    <select
                        name="CountryId"
                        value={patientData.CountryId}
                        onChange={handleInputChange}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.countries) &&
                            dropdownOptions.countries.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Name}
                                </option>
                            ))}
                    </select>
                </label>
            </div>
            <h4>Emergency Contact</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="EmergencyContactName"
                        value={patientData.EmergencyContactName}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="EmergencyContactRelationship"
                        value={patientData.EmergencyContactRelationship}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Phone Number:
                    <InputMask
                        mask="(999) 999-9999"
                        value={patientData.EmergencyContactPhone || ''}
                        onChange={(e) => {
                            const formattedPhone = stripPhoneFormatting(e.target.value);
                            handleInputChange({
                                target: {
                                    name: 'EmergencyContactPhone',
                                    value: formattedPhone
                                }
                            });
                        }}
                    />
                </label>
            </div>
            <h4>Legally Authorized Representative</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="LegalAuthRepName"
                        value={patientData.LegalAuthRepName}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="LegalAuthRepRelationship"
                        value={patientData.LegalAuthRepRelationship}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    Phone Number:
                    <InputMask
                        mask="(999) 999-9999"
                        value={patientData.LegalAuthRepPhone || ''}
                        onChange={(e) => {
                            const formattedPhone = stripPhoneFormatting(e.target.value);
                            handleInputChange({
                                target: {
                                    name: 'LegalAuthRepPhone',
                                    value: formattedPhone
                                }
                            });
                        }}
                    />
                </label>
            </div>
        </div>
    );

    const renderInsurance = () => (
        <div className="insurance-info">
            <div className="form-row">
                <label>
                    Carrier:
                    <input
                        type="text"
                        name="Insurance.Carrier"
                        value={patientData.Insurance.Carrier}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Subscriber:
                    <input
                        type="text"
                        name="Insurance.Subscriber"
                        value={patientData.Insurance.Subscriber}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Coverage:
                    <input
                        type="text"
                        name="Insurance.Coverage"
                        value={patientData.Insurance.Coverage}
                        onChange={handleInputChange}
                        maxLength={100}
                    />
                </label>
                <label>
                    Subscriber ID:
                    <input
                        type="text"
                        name="Insurance.SubscriberId"
                        value={patientData.Insurance.SubscriberId}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Group:
                    <input
                        type="text"
                        name="Insurance.Group"
                        value={patientData.Insurance.Group}
                        onChange={handleInputChange}
                        maxLength={50}
                    />
                </label>
                <label>
                    RxBIN:
                    <input
                        type="text"
                        name="Insurance.RxBIN"
                        value={patientData.Insurance.RxBIN}
                        onChange={handleInputChange}
                        maxLength={20}
                    />
                </label>
            </div>
        </div>
    );

    return (
        <div className="add-patient-form">
            <div className="form-header">
                <h2>Add New Patient</h2>
                <img src={backIcon} alt="Close" className="back-icon" onClick={onClose} />
            </div>
            <div className="tabs">
                <button
                    className={activeTab === 'demographics' ? 'active' : ''}
                    onClick={() => handleTabSwitch('demographics')}
                >
                    Demographics
                </button>
                <button
                    className={activeTab === 'contactInfo' ? 'active' : ''}
                    onClick={() => handleTabSwitch('contactInfo')}
                >
                    Contact Info
                </button>
                <button
                    className={activeTab === 'insurance' ? 'active' : ''}
                    onClick={() => handleTabSwitch('insurance')}
                >
                    Insurance
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="tab-content">
                    {activeTab === 'demographics' && renderDemographics()}
                    {activeTab === 'contactInfo' && renderContactInfo()}
                    {activeTab === 'insurance' && renderInsurance()}
                </div>
                <div className="form-buttons">
                    <button type="submit">Add Patient</button>
                </div>
            </form>
        </div>
    );
};

export default AddPatient;

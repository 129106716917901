// ClientApp/src/pages/Dashboard/PatientDataExtraction/PatientDataExtraction.js

import React from 'react';

const PatientDataExtraction = () => {
    return (
        <div>
            <h1>Patient Data Extraction</h1>
            <p>on my to-do list</p>
        </div>
    );
};

export default PatientDataExtraction;